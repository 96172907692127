import { ReactiveFormsModule } from '@angular/forms';
import { MethodosModule } from './../methodos/methodos.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SimpleModalModule } from 'ngx-simple-modal';
import { RouterModule } from '@angular/router';
import { CoreModule } from 'src/app/core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackDisplayComponent } from './feedback-display/feedback-display.component';
import { SurveyCircleSvgComponent } from './survey-circle-svg/survey-circle-svg.component';
import { SurveyQuestionComponent } from './survey-question/survey-question.component';
import { SurveyQuestionPopUpComponent } from './survey-question/survey-question-pop-up/survey-question-pop-up.component';
import { EmployeeFeedbackComponent } from './employee-feedback/employee-feedback.component';
import { CompanyFeedbackComponent } from './company-feedback/company-feedback.component';
import { ShowMethodosComponent } from '../methodos/show-methodos/show-methodos.component';
import { IdealLineDisplayComponent } from './ideal-line-display/ideal-line-display.component';
import { AddIdealPointComponent } from './ideal-line-display/add-ideal-point/add-ideal-point.component';

@NgModule({
  declarations: [FeedbackDisplayComponent, SurveyCircleSvgComponent, SurveyQuestionComponent, SurveyQuestionPopUpComponent, EmployeeFeedbackComponent, CompanyFeedbackComponent, IdealLineDisplayComponent, AddIdealPointComponent],
  imports: [
    MethodosModule,
    ReactiveFormsModule,
    CommonModule,
    CoreModule,
    RouterModule,
    SharedModule,
    SimpleModalModule.forRoot({ container: "modal-container" })
  ], entryComponents: [
    SurveyQuestionPopUpComponent,
    ShowMethodosComponent
  ]
})
export class SurveyCircleModule { }
