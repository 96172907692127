import { Injectable } from '@angular/core';
import { Point } from './point';

@Injectable({
  providedIn: 'root'
})
export class CalculatePointService {

  private center_x:number = 649;
  private center_y:number = 595;
  private distance_first_line:number = 207;
  private distance_to_last_line: number = 129;
  private number_of_lines:number = 5;

  constructor() { }

  getPoint(score: number, degree:number):Point{
    let hypotenuse:number = this.distance_first_line + this.calculateDistanceFromPoint(score);
    let base: number = hypotenuse * Math.cos(this.convertDegreeToRadian(degree));
    let height : number = hypotenuse * Math.sin(this.convertDegreeToRadian(degree));

    return new Point(this.center_x+base, this.center_y+height, ""+score.toFixed(1));
  }
  private calculateDistanceFromPoint(score:number):number{

    return (this.distance_to_last_line * score)/this.number_of_lines;
  }
  private convertDegreeToRadian(degree:number):number{
     return degree * Math.PI / 180;
  }
}
