import { GroupSurveyCircleModel } from './../model/group-survey-circle-model';
import { FetchSurveyCircleGroupsService } from './../../../core/services/survey/fetch-survey-circle-groups/fetch-survey-circle-groups.service';
import { FetchSurveyIdealAverageDto } from './../dto/fetch-survey-ideal-average-dto';
import { Message } from 'src/app/core/message-section/message';
import { MessageTypeEnum } from './../../../core/message-section/message-type-enum';
import { FetchCompanyResponsesService } from './../../../core/services/survey/fetch-company-responses/fetch-company-responses.service';
import { FetchSurveyQuestionsService } from './../../../core/services/survey/fetch-survey-questions/fetch-survey-questions.service';
import { SurveyDisplay } from 'src/app/core/services/survey/survey-enum';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TokenExpirationService } from 'src/app/core/services/auth-service/token-expiration/token-expiration.service';
import { SurveyResponse } from '../model/survey-response';
import { SurveyQuestion } from '../model/survey-question';
import { PaintGroupAveragesLine } from '../model/paint-group-averages-line';
import { GroupAverage } from '../model/group-average';

@Component({
  selector: 'app-ideal-line-display',
  templateUrl: './ideal-line-display.component.html',
  styleUrls: ['./ideal-line-display.component.scss']
})
export class IdealLineDisplayComponent implements OnInit {
  private readonly colorIdealLine: string = "#1b7d29";
  private readonly styleIdealLine: string = "10,10";

  companyId: number;
  companyName: string = "";
  readonly left: SurveyDisplay = SurveyDisplay.LEFT;
  readonly right: SurveyDisplay = SurveyDisplay.RIGHT;

  showLeft: boolean = false;
  showRight: boolean = false;
  actualValue: number;
  actualGroupId: number = 0;
  actualGroupName: string = "Survey Response Group Id";
  showMethodos: boolean = false;
  message: Message;

  responsesGroupAverages: Array<PaintGroupAveragesLine> = new Array();
  constructor(private route: ActivatedRoute,
    private fetchCompanyResponsesService: FetchCompanyResponsesService,
    private fetchQuestionsService: FetchSurveyQuestionsService,
    private tokenExpirationService: TokenExpirationService) { }

  ngOnInit() {
    this.takeCompanyIdAndNameFromParams();
    this.getIdealLine();
  }
  private takeCompanyIdAndNameFromParams() {
    this.route.params.subscribe(params => {
      this.companyId = params['companyId'];
      this.companyName = params['companyName'];
    });
  }

  private getIdealLine() {
    this.responsesGroupAverages = new Array();
    this.fetchCompanyResponsesService.getIdealAverageFromCompany(this.companyId).subscribe(idealAverage => {
      this.responsesGroupAverages.push(this.getPaintGroupAverageFromResponse(idealAverage, this.colorIdealLine, this.styleIdealLine));
    }, error => {
      this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);

    })
  }
  private getPaintGroupAverageFromResponse(response: any, colorLine: string, styleLine: string): PaintGroupAveragesLine {
    let averages: Array<FetchSurveyIdealAverageDto> = response;
    let groupAverages: Array<GroupAverage> = new Array();
    averages.filter(average => { return average.average !== null }).forEach(idealAverage => {
      groupAverages.push(new GroupAverage(idealAverage.surveyQuestionGroupId, idealAverage.average, FetchSurveyCircleGroupsService.getDegreeFromGroupId(idealAverage.surveyQuestionGroupId, 0.5)))
    });
    return new PaintGroupAveragesLine(groupAverages, colorLine, styleLine);
  }
  onInformationClicked(idGroup: number) {
    this.showMethodos = true;
    this.actualGroupId = idGroup;
    this.actualGroupName = FetchSurveyCircleGroupsService.getGroupNameByGroupID(idGroup);
    FetchSurveyCircleGroupsService.hideAllLinesExceptFromGroupById(idGroup);
    this.checkShowDisplay(FetchSurveyCircleGroupsService.getDisplayByGroupId(idGroup));
  }
  onGroupClicked(group: GroupSurveyCircleModel) {
    this.showMethodos = false;
    FetchSurveyCircleGroupsService.hideAllLinesExceptFromGroupById(group.id);
    this.actualGroupName = group.groupName;
    this.actualGroupId = group.id;
    let average: GroupAverage = this.responsesGroupAverages[0].groupAverages.find(average => { return average.groupId === group.id });
    (average !== undefined) ? this.actualValue = average.responseAverage : this.actualValue = null;
    this.checkShowDisplay(group.display);

  }
  private checkShowDisplay(display: SurveyDisplay) {
    if (display === SurveyDisplay.LEFT) {
      this.showInLeft();
    }
    else {
      this.showInRight();
    }
  }
  private showInLeft() {
    this.showLeft = true;
    this.showRight = false;
    window.scrollTo(0, 0);
  }
  private showInRight() {
    this.showLeft = false;
    this.showRight = true;
    window.scrollTo(1500, 0);
  }
  private closeQuestion(survey: SurveyDisplay) {
    if (survey === SurveyDisplay.LEFT) {
      this.showLeft = false;
    } else {
      this.showRight = false;
    }
    FetchSurveyCircleGroupsService.hideALl();
  }
  private saveIdealPoint(isAdded: boolean) {
    if (isAdded === true) {
      this.getIdealLine();
    }
    this.showLeft = false;
    this.showRight = false;
    FetchSurveyCircleGroupsService.hideALl();
  }
}
