import { FetchMethodoInfoService } from './../../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { Component, OnInit, Input } from '@angular/core';
import { FetchMethodeInputDTO } from '../../dto/fetch-methode-input-dto';

@Component({
  selector: 'methode-input-info',
  templateUrl: './methode-input-info.component.html',
  styleUrls: ['./methode-input-info.component.scss']
})
export class MethodeInputInfoComponent implements OnInit {
  @Input() methodeId: number;
  public inputs: Array<FetchMethodeInputDTO> = new Array();
  constructor(private fetchMethodoInfoService: FetchMethodoInfoService) { }

  ngOnInit() {
    this.fetchMethodoInfoService.getMethodeInputInfo(this.methodeId).subscribe(response => {
      this.inputs = response;
    })
  }

}
