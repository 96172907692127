
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkExperiencesService {
  private static workExperiences:Array<string>= ['bis 1 Jahr',
  '2 bis 5 Jahre', '6 bis 10 Jahre', '11 bis 20 Jahre','mehr als 20 Jahre'];

  static getWorkExperienceByIndex(index:number):string{
    if(index > this.workExperiences.length || index <= 0){
      throw new Error("Illegal argument in get Berufserfahrung");
    }
    return this.workExperiences[index-1];
  }
  static getWorkExperiencesSize():number{
    return this.workExperiences.length;
  }
  static getAllWorkExperiences():Array<string>{
    return this.workExperiences;
  }
  static getIdFromResponsability(workExperienceToSearch:string):number{
    return this.workExperiences.findIndex(workExperience => workExperience===workExperienceToSearch)+1;

  }
};
