import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  message:string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.getMessagefromUrl();
  }
  private getMessagefromUrl() {
    this.route.params.subscribe(params => {
      this.message = params['logoutMessage'];
    });
  }

}
