import { DisplayCompaniesComponent } from './../companies/display-companies/display-companies.component';
import { CompaniesModule } from './../companies/companies.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [
    DashboardComponent,

  ],
  imports: [
    CommonModule,
    CoreModule,
    CompaniesModule
  ]
})
export class DashboardModule { }
