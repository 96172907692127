
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FetchMethodosInfoRepository{
  constructor(private http: HttpClient){

  }
  getMehotodoMainInfo(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.mainInfo;
    return this.http.get(url);
  }
  getMehotodoStrengthsAndWeaknesses(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.strengthsAndWeaknesses;
    return this.http.get(url);
  }
  getMethodoImplementationsInfo(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.implementations;
    return this.http.get(url);
  }

  getMethodoPraxiExamplesInfo(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.praxisExamples;
    return this.http.get(url);
  }
  getMethodoLinksInfo(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.links;
    return this.http.get(url);
  }
  getMethododevelopmentsInfo(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.developments;
    return this.http.get(url);
  }
  getMethodoAssignmentsInfo(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.assignments;
    return this.http.get(url);
  }
  getMethodoCommentsInfo(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.comments;
    return this.http.get(url);
  }
  getMethodoLiteratureInfo(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.literatures;
    return this.http.get(url);
  }
  getMethodoKeywords(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.keywords;
    return this.http.get(url);
  }
  getMethodeInputInfo(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.inputs;
    return this.http.get(url);
  }
  getMethodeOutputInfo(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.outputs;
    return this.http.get(url);
  }
  getMethodosList():Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos;
    return this.http.get(url);
  }
}
