export class Methodo {
  public goal: string;
  public trainingEffort: number;
  public timeEffort: number;
  public teamSize: string;
  public moderator: number;
  public warnings: Array<string>;

  constructor(goal: string, trainingEffort: number, timeEffort: number, moderator: number, teamSize: string, warnings: Array<string>) {
    this.goal = goal;
    this.trainingEffort = trainingEffort;
    this.timeEffort = timeEffort;
    this.teamSize = teamSize;
    this.warnings = warnings;
    this.moderator = moderator;
  }
}
