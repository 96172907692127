
export class SurveyResponse{
  public questionId:number;
  public question: string;
  public questionGroup: number;
  public response: number;
  constructor(questionId:number, question:string, questionGroup:number, response:number){
    this.question = question;
    this.questionId = questionId;
    this.questionGroup = questionGroup;
    this.response = response;
  }
}
