
import { SearchCompanyRepository } from './../../../repositories/companies/search/search-company-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchCompaniesService {

  constructor(private searchCompanyRepository: SearchCompanyRepository) { }

  getAllCompanies(): Observable<any> {
    return this.searchCompanyRepository.getCompaniesFromUser();
  }
}
