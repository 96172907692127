import { FetchSurveyRepository } from '../../../repositories/survey/fetch-survey-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FetchEmployeeSurveyService {

  constructor(private fetchSurveyRepository: FetchSurveyRepository) { }

  getSurveyFromEmployee(employeeToken: string): Observable<any>{
    return this.fetchSurveyRepository.getSurveyFromEmployee(employeeToken);
  }
}
