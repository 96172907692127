import { TokenExpirationService } from './../../../../core/services/auth-service/token-expiration/token-expiration.service';
import { Router } from '@angular/router';
import { SendEmailService } from './../../../../core/services/send-email/send-email.service';
import { ChangeFeedbackstatusDto } from './../../dto/change-feedbackstatus-dto';
import { GetFeedbackstatusService } from './../../../../core/services/companies/get-feedbackstatus/get-feedbackstatus.service';
import { EmployeeDTOToEmployeeMapper } from './../../../employees/mapper/employee-dto-to-employee-mapper';
import { EmployeeDTO } from './../../../employees/dto/employee-dto';
import { SearchEmployeesService } from './../../../../core/services/employees/search/search-employees.service';
import { DeleteCompanyComponent } from './../../delete-company/delete-company.component';
import { DeleteCompanyService } from './../../../../core/services/companies/delete/delete-company.service';
import { Company } from './../../domain/company';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { Employee } from 'src/app/modules/employees/model/employee';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'display-company',
  templateUrl: './display-company.component.html',
  styleUrls: ['./display-company.component.scss']
})
export class DisplayCompanyComponent implements OnInit {
  @Input() company: Company;
  @Input() companies: Array<Company>
  @Output() companyDeleted = new EventEmitter<number>();

  isOpen = true;
  disabledEmailButton = true;
  confirmDeleteResult = null;
  employees: Array<Employee> = new Array<Employee>();

  constructor(private simpleModalService: SimpleModalService,
    private deleteCompanyService: DeleteCompanyService,
    private searchEmployeeService: SearchEmployeesService,
    private getFeedbackStatusService: GetFeedbackstatusService,
    private ng4LoadingSpinnerService: Ng4LoadingSpinnerService,
    private sendEmailService: SendEmailService,
    private tokenExpirationService: TokenExpirationService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onCompanyChanged(isChanged: boolean) {
    this.getFeedbackStatusService.getFeedbackStatusFromCompany(this.company.id).subscribe(response => {
      let newFeedback: ChangeFeedbackstatusDto = response;
      this.company.feedbackStatus = newFeedback.feedbackstatus;
    }, error => {
      this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
    });
    this.setEmailButtonStatus(this.employees.length);
  }
  removeCompany() {
    this.simpleModalService.addModal(DeleteCompanyComponent, {
      title: 'Sie löschen die angelegte Firma, alle darunter angelegten Mitarbeiter und das dazugehörige gesammelte Feedback. Sind Sie sicher?',
      message: 'Eintrag Löschen'
    })
      .subscribe(isConfirmed => {
        this.confirmDeleteResult = isConfirmed;
        if (this.confirmDeleteResult === true) {
          this.companyDeleted.emit(this.company.id);
          this.deleteCompanyService.deleteCompany(this.company.id).subscribe(response => {
            this.setEmailButtonStatus(this.employees.length);
          }, error => {
            this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
          });
        }
        this.confirmDeleteResult = false;
      }, error => {
        this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
      });

  }
  openCompany() {
    if (this.isOpen) {
      this.ng4LoadingSpinnerService.show();
      this.searchEmployeeService.getAllEmployeesFromCompany(this.company.id).subscribe(response => {
        let employees: Array<EmployeeDTO> = response;
        employees.forEach(employee => {
          this.employees.push(EmployeeDTOToEmployeeMapper.map(employee));
        })
        this.setEmailButtonStatus(employees.length);
        this.ng4LoadingSpinnerService.hide();
        this.isOpen = false;
      }, error => {
        this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
      });
    } else {
      this.employees.splice(0, this.employees.length);
      this.isOpen = true;
      this.disabledEmailButton = true;
    }
  }

  private setEmailButtonStatus(employeesLength: number): void {
    if (employeesLength > 0) {
      this.disabledEmailButton = false;
    } else {
      this.disabledEmailButton = true;
    }
  }

  sendEmail() {
    this.sendEmailsToAllEmployees();
  }

  private sendEmailsToAllEmployees() {
    let memployees: Array<Employee> = this.employees.slice(0);
    this.sendEmailToEmployee(memployees);
  }

  private sendEmailToEmployee(memployees: Array<Employee>) {
    if (memployees.length > 0) {
      setTimeout(() => {
        let employee = memployees.shift();
        let employeeElement: HTMLElement = document.getElementById("email-employee-" + employee.id);
        this.changeToSpinner(employeeElement);
        this.sendEmailService.sendEmailToEmployee(this.company.id, employee.id).subscribe(response => {
          this.changeSpinnerToCheck(employeeElement);
        }, error => {
          this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
          this.changeToError(employeeElement);
        });
        this.sendEmailToEmployee(memployees);
      }, 100);
    }
  }

  openFeedBackStatus() {
    this.router.navigate(['/company', this.company.companyName, this.company.id]);
  }
  openIdealLineDisplay() {
    this.router.navigate(['/ideal-line', this.company.companyName, this.company.id]);
  }
  private changeToSpinner(element: HTMLElement) {
    element.classList.remove('fa', 'fa-envelope', 'fa-envelope-open', 'fa-check', 'fa-time');
    element.classList.add('spinner-border');
  }

  private changeSpinnerToCheck(element: HTMLElement) {
    setTimeout(() => {
      element.classList.remove('spinner-border');
      element.classList.add('fa', 'fa-check');
      setTimeout(() => {
        this.changeToSent(element);
      }, 2000);
    }, 500);
  }

  private changeToSent(element: HTMLElement) {
    element.classList.remove('fa', 'fa-envelope', 'fa-envelope-open', 'fa-check', 'fa-time', 'spinner-border');
    element.classList.add('fa', 'fa-envelope-open');
  }

  private changeToError(element: HTMLElement) {
    element.classList.remove('fa', 'fa-envelope', 'fa-envelope-open', 'fa-check', 'fa-time', 'spinner-border');
    element.classList.add('fa', 'fa-times');
  }
}
