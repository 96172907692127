import { UpdatePasswordDTO } from './../../modules/password/dto/update-password-dto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PasswordUpdateRepository{

  constructor(private httpClient: HttpClient){
  }

  updatePassword(url: string, password: UpdatePasswordDTO): Observable<any>{
    let body: string = JSON.stringify(password);
    return this.httpClient.put(url, body);
  }
}
