import { SearchEmployeesRepository } from './../../../repositories/employees/search/search-employees-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchEmployeesService {

  constructor(private searchEmployeeRepository:SearchEmployeesRepository) { }

  getAllEmployeesFromCompany(companyId:number):Observable<any>{
    return this.searchEmployeeRepository.getEmployeesFromCompany(companyId);
  }
}
