import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InfoElemenet {
  infoCircleId: string;
  infoIId:string;
  infoSectionId:string
  groupId: number;

  constructor(groupId: number,infoSection:string, infoIId:string, infoCircleId: string) {
    this.groupId = groupId;
    this.infoSectionId =infoSection;
    this.infoIId = infoIId;
    this.infoCircleId = infoCircleId;
  }
}
export class GetInfoElementsIdService {
  public static readonly infoCirclesIds: Array<InfoElemenet> = [
    new InfoElemenet(1, "Infopunkt-Ressourcen","Infopunkt-Ressourcen-i","Infopunkt-Ressourcen-Circle"),
    new InfoElemenet(2, "Infopunkt-Führung_und_Unternehmenskultur","Infopunkt-Führung_und_Unternehmenskultur-i","Infopunkt-Führung_und_Unternehmenskultur-Circle"),
    new InfoElemenet(3, "Infopunkt-Innovationsstrategie","Infopunkt-Innovationsstrategie-i","Infopunkt-Innovationsstrategie-Circle"),
    new InfoElemenet(4, "Infopunkt-Prozessmanagement","Infopunkt-Prozessmanagement-i","Infopunkt-Prozessmanagement-Circle"),
    new InfoElemenet(5, "Infopunkt-Organisationsstrukturen","Infopunkt-Organisationsstrukturen-i","Infopunkt-Organisationsstrukturen-Circle"),
    new InfoElemenet(6, "Infopunkt-Forschung_und_Entwicklung","Infopunkt-Forschung_und_Entwicklung-i","Infopunkt-Forschung_und_Entwicklung-Circle"),
    new InfoElemenet(7, "Infopunkt-Ideenmanagement","Infopunkt-Ideenmanagement-i","Infopunkt-Ideenmanagement-Circle"),
    new InfoElemenet(8, "Infopunkt-Wissensmanagement","Infopunkt-Wissensmanagement-i","Infopunkt-Wissensmanagement-Circle"),
    new InfoElemenet(9, "Infopunkt-Qualifikation_und_Kompetenzen","Infopunkt-Qualifikation_und_Kompetenzen-i","Infopunkt-Qualifikation_und_Kompetenzen-Circle"),
    new InfoElemenet(10, "Infopunkt-Status_der_Produktion","Infopunkt-Status_der_Produktion-i","Infopunkt-Status_der_Produktion-Circle"),
    new InfoElemenet(11, "Infopunkt-Innovationspotentiale","Infopunkt-Innovationspotentiale-i","Infopunkt-Innovationspotentiale-Circle"),
    new InfoElemenet(12, "Infopunkt-Trend-_und_Technologieorientierung","Infopunkt-Trend-_und_Technologieorientierung-i","Infopunkt-Trend-_und_Technologieorientierung-Circle"),
    new InfoElemenet(13, "Infopunkt-Kundenorientierung","Infopunkt-Kundenorientierung-i","Infopunkt-Kundenorientierung-Circle"),
    new InfoElemenet(14, "Infopunkt-Nutzerorientierung","Infopunkt-Nutzerorientierung-i","Infopunkt-Nutzerorientierung-Circle"),
    new InfoElemenet(15, "Infopunkt-Lieferanteneinbindung","Infopunkt-Lieferanteneinbindung-i","Infopunkt-Lieferanteneinbindung-Circle"),
    new InfoElemenet(16, "Infopunkt-Wettbewerbsberücksichtigung","Infopunkt-Wettbewerbsberücksichtigung-i","Infopunkt-Wettbewerbsberücksichtigung-Circle"),
    new InfoElemenet(17, "Infopunkt-Vermarktung","Infopunkt-Vermarktung-i","Infopunkt-Vermarktung-Circle"),
    new InfoElemenet(18, "Infopunkt-Kooperation_mit_wissenschaftlichen_Einrichtungen","Infopunkt-Kooperation_mit_wissenschaftlichen_Einrichtungen-i","Infopunkt-Kooperation_mit_wissenschaftlichen_Einrichtungen-Circle"),
    new InfoElemenet(19, "Infopunkt-Kooperation_mit_Unternehmen_und_Konsortien","Infopunkt-Kooperation_mit_Unternehmen_und_Konsortien-i","Infopunkt-Kooperation_mit_Unternehmen_und_Konsortien-Circle")
  ];
  constructor() { }

  public static getInfoIdByGroupId(groupId:number):InfoElemenet{
    return this.infoCirclesIds.find(info => info.groupId === groupId);

  }

}

