import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'link-methodos',
  templateUrl: './link-methodos.component.html',
  styleUrls: ['./link-methodos.component.scss']
})

export class LinkMethodosComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }
  goToMethodos(){
    this.router.navigate(['/methodes']);
  }
}
