import { range } from 'rxjs';

export class RangeDegrees{
  initRange:number;
  finalRange:number;
  constructor(initRange:number, finalRange:number){
    this.initRange = initRange;
    this.finalRange = finalRange;
  }
}
