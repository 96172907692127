import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DeleteCompanyRepository{
  constructor(private http:HttpClient){

  }
  deleteCompany( companyID:number):Observable<any>{
    const url: string = environment.rootUrl + environment.endpoints.companies + "/"+ companyID;
    return this.http.delete(url);
  }
}
