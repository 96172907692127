
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SaveResponseDto } from 'src/app/modules/survey-circle/dto/save-response-dto';
@Injectable({
  providedIn: 'root'
})
export class SaveQuestionRepository{
  constructor(private http: HttpClient){

  }

  saveNewQuestion(question:SaveResponseDto):Observable<any>{
    const url: string = environment.rootUrl + environment.endpoints.survey+"/"+environment.endpoints.response;
    let body = JSON.stringify(question);
    return this.http.post(url, body);
  }
  updateQuestion(question:SaveResponseDto):Observable<any>{
    const url: string = environment.rootUrl + environment.endpoints.survey+"/"+environment.endpoints.response;
    let body = JSON.stringify(question);
    return this.http.patch(url, body);
  }

}
