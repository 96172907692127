import { TokenExpirationService } from './../../../core/services/auth-service/token-expiration/token-expiration.service';
import { EmployeeDTOToEmployeeMapper } from './../mapper/employee-dto-to-employee-mapper';
import { EmployeeDTO } from './../dto/employee-dto';
import { AddEmployeeToAddEmployeeDTOMapper } from './../mapper/add-employee-to-add-employee-dto-mapper';
import { AddEmployeeService } from './../../../core/services/employees/add-employee/add-employee.service';
import { AddEmployee } from './../model/add-employee';
import { DepartmentsService } from 'src/app/shared/services/employees/departments/departments.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Employee } from 'src/app/modules/employees/model/employee';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ValidationMessageService } from 'src/app/shared/services/validation-message-service/validation-message.service';
import { WorkExperiencesService } from 'src/app/shared/services/employees/work-experiences/work-experiences.service';
import { ResponsabilitiesService } from 'src/app/shared/services/employees/responsabilities/responsabilities.service';

@Component({
  selector: 'add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit {
  private  redError:string = "#e26868";
  private greenSucces:string = "#71bc4b";
  private readonly secondsToResetSuccessMessage: number = 5;

  @Input() companyId: number;
  @Input() private employees: Array<Employee> = new Array();
  @Output() employeeAdded = new EventEmitter<boolean>();

  textMessage: string;
  addEmployeeForm: FormGroup;
  validationMessages: any;
  activateForm: boolean;
  private defaultOption: string;
  experiences: Array<string>;
  departments: Array<string>;
  responsabilities: Array<string>;

  constructor(private formBuilder: FormBuilder, private validationMessageService: ValidationMessageService
    , private addEmployeeService: AddEmployeeService, private tokenExpirationService: TokenExpirationService) { }

  ngOnInit() {
    this.addEmployeeForm = this.createFormGroup();
    this.validationMessages = this.createValidationMessages();
    this.textMessage = '';
    this.defaultOption = "Bitte wählen"
    this.activateForm = false;
    this.experiences = WorkExperiencesService.getAllWorkExperiences();
    this.departments = DepartmentsService.getAllDepartments();
    this.responsabilities = ResponsabilitiesService.getAllResponsibilities();
  }

  saveEmployee() {
    if (this.addEmployeeForm.invalid) {
      this.textMessage = this.formatErrors(this.mapErrorMessages());
      this.changeColorMessage(this.redError);
    } else {
      let employee: AddEmployee = this.takeEmployeeFromFormResponse(this.addEmployeeForm.value);
      this.addEmployeeService.addEmployee(this.companyId, AddEmployeeToAddEmployeeDTOMapper.map(employee)).subscribe(
        response => {
          let newEmployee: EmployeeDTO = response;
          this.employees.push(EmployeeDTOToEmployeeMapper.map(newEmployee));
          this.employeeAdded.emit(true);
          this.textMessage='Mitarbeiter wurde erfolgreich erstellt';
          this.changeColorMessage(this.greenSucces);
          this.addEmployeeForm.reset();
          this.resetMessage();
        },
        error => {
          this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
          let response: Response = error;
          if (response.status === 409) {
            this.textMessage = "Die E-mail wird bereits verwendet";
          }
          else if (error.status >= 500) {
            this.textMessage = "Bei der Kommunikation mit dem Server ist ein Fehler aufgetreten!";
          }
          else {
            this.textMessage = "Es konnte keine Verbindung zum Server hergestellt werden.";
          }
          this.changeColorMessage(this.redError);
        }
      )

    }
  }

  private resetMessage():void{
    setTimeout(() => {
      this.textMessage = "";
    }, this.secondsToResetSuccessMessage*1000);
  }

  private formatErrors(errors: Array<string>): string{
    let output:string = "";
    errors.forEach(error => {
      output += error+'<br />';
    })
    return output;
  }

  takeEmployeeFromFormResponse(response: any): AddEmployee {
    let addEmployee: AddEmployee = new AddEmployee();
    addEmployee.email = response.employeeEmail;
    addEmployee.department = DepartmentsService.getIdFromDepartment(response.employeeDepartment);
    addEmployee.responsability = ResponsabilitiesService.getIdFromResponsability(response.employeeResponsability);
    addEmployee.workexperience = WorkExperiencesService.getIdFromResponsability(response.employeeExperience);

    return addEmployee;
  }
  private createValidationMessages(): any {
    return (
      {
        employeeEmail: {
          required: "Die E-Mailadresse des Mitarbeiters ist eine Pflichtangabe.",
          maxlength: "Die E-Mail Adresse ist zu lang",
          email: "Die E-Mailadresse des Mitarbeiters hat kein korrektes Format."
        },
        employeeDepartment: {
          required: "Bereich/Abteilung des Mitarbeiters ist eine Pflichtangabe.",
        },
        employeeResponsability: {
          required: "Personalverantwortung des Mitarbeiters ist eine Pflichtangabe.",
        },
        employeeExperience: {
          required: "Berufserfahrung des Mitarbeiters ist eine Pflichtangabe.",
        }
      });
  }
  showForm() {
    this.activateForm = !this.activateForm;
    if (this.activateForm === false) {
      this.textMessage = '';
      this.addEmployeeForm.reset();

    }
  }
  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      employeeEmail: ["", [Validators.required, Validators.maxLength(150), Validators.email]],
      employeeDepartment: ["", [Validators.required]],
      employeeResponsability: ["", [Validators.required]],
      employeeExperience: ["", [Validators.required]]
    })
  }
  private mapErrorMessages(): Array<string> {
    return this.validationMessageService.mapErrorMessages(this.addEmployeeForm, this.validationMessages);
  }
  private changeColorMessage(color:string){
    let messagetext:HTMLElement = document.getElementById("text-employee-message-"+this.companyId);
    messagetext.style.color = color;

  }
}
