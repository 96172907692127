import { MessageTypeEnum } from './../../../../core/message-section/message-type-enum';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ConfirmModel } from './../../../companies/delete-company/delete-company.component';
import { Message } from 'src/app/core/message-section/message';


@Component({
  selector: 'survey-pop-up',
  templateUrl: './survey-question-pop-up.component.html',
  styleUrls: ['./survey-question-pop-up.component.scss']
})
export class SurveyQuestionPopUpComponent extends SimpleModalComponent<ConfirmModel, number>{
  title: string;
  message: string;
  response: number;
  actualSelect: string;
  errorMessage: Message;

  constructor() {
    super();
  }
  ngOnInit() {
    this.response = parseInt(this.message);
    this.actualSelect = this.message;
  }
  setResponse(response: number) {
    this.actualSelect = "" + response;
    this.response = response;

  }
  confirm() {
    if (!this.response) {
      this.errorMessage = new Message(MessageTypeEnum.ERROR, ["Bitte wählen Sie einen Wert."])
    } else {
      this.result = this.response;
      this.close();
    }
  }
  cancel() {
    this.result = null;
    this.close();
  }

}
