import { AddIdealPointService } from './../../../../core/services/companies/add-ideal-line-point/add-ideal-point.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TokenExpirationService } from 'src/app/core/services/auth-service/token-expiration/token-expiration.service';
import { ValidationMessageService } from 'src/app/shared/services/validation-message-service/validation-message.service';
import { IdealPoint } from '../model/ideal-point';

@Component({
  selector: 'add-ideal-point',
  templateUrl: './add-ideal-point.component.html',
  styleUrls: ['./add-ideal-point.component.scss']
})
export class AddIdealPointComponent implements OnInit {

  @Input() groupName: string = "Group Name"
  @Input() groupId: number;
  @Input() value: number;
  @Input() companyId: number;
  @Output() idealPointAdded = new EventEmitter<boolean>();

  addIdealLineForm: FormGroup;
  private validationMessages: any;
  errorMessage: string = "";
  constructor(private formBuilder: FormBuilder, private idealPointService: AddIdealPointService, private validationMessageService: ValidationMessageService, private tokenExpirationService: TokenExpirationService) { }

  ngOnInit() {
    this.addIdealLineForm = this.createFormGroup();
    this.validationMessages = this.createValidationMessages();
  }
  ngOnChanges() {
    this.errorMessage = ""
  }
  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      idealPoint: ["", [Validators.min(1), Validators.max(5), Validators.pattern("([1-4](\.[0-9][0-9]?)?)|(5(\.[0][0]?)?)")]]
    })
  }
  private createValidationMessages(): any {
    return (
      {
        idealPoint: {
          min: "Der eingegebene Wert muss zwischen 1 - 5 liegen.",
          max: "Der eingegebene Wert muss zwischen 1 - 5 liegen.",
          pattern: "Der eingegebene Wert muss zwischen 1 - 5 liegen."
        }
      });
  }

  saveIdealPoint() {
    if (this.addIdealLineForm.invalid) {
      this.errorMessage = this.mapErrorMessages()[0];
    } else {
      this.errorMessage = null;
      let average: number = this.addIdealLineForm.value.idealPoint.replace(",",".");
      let idealPoint: IdealPoint = new IdealPoint(this.groupId, (average >= 1 && average <= 5) ? average : 0);
      this.idealPointService.addIdealPoint(this.companyId, idealPoint).subscribe(response => {
        this.idealPointAdded.emit(true)
      }, error => {
        this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
        this.errorMessage = "Ein unerwarteter Fehler ist aufgetreten.";
      });

    }
  }
  private mapErrorMessages(): Array<string> {
    return this.validationMessageService.mapErrorMessages(this.addIdealLineForm, this.validationMessages);
  }
  closePopUp() {
    this.idealPointAdded.emit(false);
  }
}
