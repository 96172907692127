import { SimpleModalComponent } from 'ngx-simple-modal';
import { ConfirmModel } from './../../companies/delete-company/delete-company.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'delete-employee',
  templateUrl: './delete-employee.component.html',
  styleUrls: ['./delete-employee.component.scss']
})
export class DeleteEmployeeComponent extends SimpleModalComponent<ConfirmModel, boolean>{
  title: string;
  message: string;
  constructor() {
    super();
  }

  confirm() {
    this.result = true;
    this.close();
  }
  cancel() {
    this.result = false;
    this.close();
  }
}
