import { AuthService } from './../services/auth-service/auth.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { nextContext } from '@angular/core/src/render3';

@Injectable()
export class JwtInterceptor implements HttpInterceptor{

  constructor(private authService: AuthService){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string = this.authService.getToken();
    if (token){
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer '+token+''
        }
      });
    }
    return next.handle(request);
  }
}
