
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-methodo-info',
  templateUrl: './show-methodo-info.component.html',
  styleUrls: ['./show-methodo-info.component.scss']
})
export class ShowMethodoInfoComponent implements OnInit {

  methodeId:number;
  constructor(private route:ActivatedRoute) { }
  ngOnInit() {
    this.getIdFromUrl();
  }
  private getIdFromUrl(){
    this.route.params.subscribe(params => {
      this.methodeId = params['methodeId'];
    });
  }


}
