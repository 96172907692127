import { FetchMethodoInfoService } from './../../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { FetchMethodeLiteratureDto} from './../../dto/fetch-methode-literature-dto';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'literature',
  templateUrl: './literature.component.html',
  styleUrls: ['./literature.component.scss']
})
export class LiteratureComponent implements OnInit {

  @Input() methodeId: number;
  literatures: Array<FetchMethodeLiteratureDto> = new Array();
  constructor(private fetchMethodoInfoService: FetchMethodoInfoService) { }

  ngOnInit() {
    this.fetchMethodoInfoService.getMethodoLiteratureInfo(this.methodeId).subscribe(response => {
      this.literatures = response;
    });
  }

}
