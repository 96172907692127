export class Point{
  public cx:number;
  public cy:number;
  public title:string;
  constructor(cx:number, cy: number, title:string){
    this.title = title;
    this.cx = cx;
    this.cy = cy;
  }
}
