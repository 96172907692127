import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayCompaniesComponent } from './display-companies/display-companies.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddCompanyComponent } from './add-company/add-company.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { SimpleModalModule } from 'ngx-simple-modal';
import { DeleteCompanyComponent } from './delete-company/delete-company.component';
import { EmployeesModule } from '../employees/employees.module';
import { DisplayCompanyComponent } from './display-companies/display-company/display-company.component';
import { DisplayCompanyHeaderComponent } from './display-companies/display-company-header/display-company-header.component';

@NgModule({
  declarations: [
    AddCompanyComponent,
    DisplayCompaniesComponent,
    AddCompanyComponent,
    DeleteCompanyComponent,
    DisplayCompanyComponent,
    DisplayCompanyHeaderComponent
  ],
  imports: [
    EmployeesModule,
    ReactiveFormsModule,
    SharedModule,
    CommonModule,
    NgxPaginationModule,
    Ng4LoadingSpinnerModule,
    SimpleModalModule.forRoot({ container: "modal-container" })
  ],
  exports: [
    DisplayCompaniesComponent
  ], entryComponents: [
    DeleteCompanyComponent
  ]
})
export class CompaniesModule { }
