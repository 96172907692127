import { FetchSurveyRepository } from './../../../repositories/survey/fetch-survey-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FetchSurveyQuestionsService {

  constructor(private fetchSurveyRepository: FetchSurveyRepository) { }

  public getSurveyQuestions():Observable<any>{
    return this.fetchSurveyRepository.getSurveyQuestions();
  }
}
