import { GroupAverage } from './group-average';
export class PaintGroupAveragesLine{
  groupAverages : Array<GroupAverage>;
  color: string;
  lineStyle: string;
  constructor(groupAverages: Array<GroupAverage>, color:string, lineStyle:string){
    this.groupAverages = groupAverages;
    this.color = color;
    this.lineStyle = lineStyle;
  }
}
