import { LoginUser } from 'src/app/modules/login/models/login-user';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginRepository {

  constructor(private httpClient: HttpClient) {
  }

  login(url: string, loginUser: LoginUser): Observable<any> {
    let body: string = JSON.stringify(loginUser)
    return this.httpClient.post(url, body);
  }
}
