import { FetchSurveyRepository } from '../../../repositories/survey/fetch-survey-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FetchEmployeeResponsesService {

  constructor(private fetchSurveyRepository: FetchSurveyRepository) { }

  public getEmployeeResponses(employeeID:number):Observable<any>{
    return this.fetchSurveyRepository.getEmployeeResponses(employeeID);
  }

}
