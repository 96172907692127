import { AuthService } from '../../../../core/services/auth-service/auth.service';
import { Message } from './../../../../core/message-section/message';
import { LoginUser } from './../../models/login-user';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageTypeEnum } from 'src/app/core/message-section/message-type-enum';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ValidationMessageService } from 'src/app/shared/services/validation-message-service/validation-message.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Input() errorMessage: string;
  loginForm: FormGroup;
  private loginUser: LoginUser;
  message: Message;
  private validationMessages: any;
  contactFormLink: string = environment.contactform;


  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private validationMessageService: ValidationMessageService) { }



  ngOnInit() {
    if (this.errorMessage != null) {
      this.message = new Message(MessageTypeEnum.ERROR, [this.errorMessage]);
    }
    this.loginForm = this.createFormGroup();
    this.validationMessages = this.createValidationMessages();
  }

  private createValidationMessages(): any {
    return (
      {
        username: {
          required: "E-Mail Adresse fehlt",
          email: "Ungültige E-Mail Adresse"
        },
        password: {
          required: "Kennwort fehlt"
        }
      });
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group(
      {
        username: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required]]
      }
    );
  }

  login() {
    this.loginForm.reset(this.loginForm.value);
    if (this.loginForm.invalid) {
      this.message = new Message(MessageTypeEnum.ERROR, this.mapErrorMessages());
    }
    else {
      this.loginUser = new LoginUser(this.loginForm.value);

      this.authService.login(this.loginUser).subscribe(
        response => {
          this.loginForm.reset();
          this.router.navigate(['/']);
        },
        error => {
          if (error.status >= 400 && error.status < 500) {
            this.message = new Message(MessageTypeEnum.ERROR, ["Falscher Benutzername und/oder falsches Passwort."]);
          }
          else if (error.status >= 500) {
            this.message = new Message(MessageTypeEnum.ERROR, ["Bei der Kommunikation mit dem Server ist ein Fehler aufgetreten!"]);
          }
          else {
            this.message = new Message(MessageTypeEnum.ERROR, ["Es konnte keine Verbindung zum Server hergestellt werden."]);
          }
        }
      );
    }
  }

  private mapErrorMessages(): Array<string> {
    return this.validationMessageService.mapErrorMessages(this.loginForm, this.validationMessages);
  }




}
