import { FetchmethodeDevelopmentsDto, ResourcesMethodeDto } from './../../dto/fetch-methode-developments-dto';
import { FetchMethodoInfoService } from './../../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment.testing';

@Component({
  selector: 'methode-execution-info',
  templateUrl: './methode-execution-info.component.html',
  styleUrls: ['./methode-execution-info.component.scss']
})
export class MethodeExecutionInfoComponent implements OnInit {
  @Input() methodeId: number;
  methodoDevelop: FetchmethodeDevelopmentsDto = new FetchmethodeDevelopmentsDto();
  constructor(private fetchMethodoInfoService: FetchMethodoInfoService) { }


  ngOnInit() {
    this.fetchMethodoInfoService.getMethododevelopmentsInfo(this.methodeId).subscribe(response => {
      this.methodoDevelop = response;
      this.methodoDevelop.resources.forEach(resource => {
        (resource.attachment === undefined || resource.attachment === "")?resource.attachment = null: resource.attachment = environment.mediaPathURl + resource.attachment
      })
    });

  }
}
