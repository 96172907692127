
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResponsabilitiesService {
  private static responsibilities:Array<string>= ['Nein',
  'Ja, nur fachlich', 'Ja, fachlich und disziplinarisch'];

  static getResponsabilityByIndex(index:number):string{
    if(index > this.responsibilities.length || index <= 0){
      throw new Error("Illegal argument in get Personalverantwortung");
    }
    return this.responsibilities[index-1];
  }
  static getResponsibilitiesSize():number{
    return this.responsibilities.length;
  }
  static getAllResponsibilities():Array<string>{
    return this.responsibilities;
  }
  static getIdFromResponsability(responsabilityToSearch:string):number{
    return this.responsibilities.findIndex(responsability => responsability===responsabilityToSearch)+1;
  }
};
