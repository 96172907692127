import { Router } from '@angular/router';
import { FetchMethodoInfoService } from './../../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { FetchMethodeLinksDto } from './../../dto/fetch-methode-links-dto';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'methode-links',
  templateUrl: './methode-links.component.html',
  styleUrls: ['./methode-links.component.scss']
})
export class MethodeLinksComponent implements OnInit {

  @Input() methodeId: number;
  linksMethode: FetchMethodeLinksDto = new FetchMethodeLinksDto();
  constructor(private router: Router, private fetchMethodoInfoService: FetchMethodoInfoService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.fetchMethodoInfoService.getMethodoLinksInfo(this.methodeId).subscribe(response => {
      this.linksMethode = response;
      (this.linksMethode.videotutorial === '' || this.linksMethode.videotutorial === undefined) ? this.linksMethode.videotutorial = null : this.linksMethode.videotutorial = environment.mediaPathURl + this.linksMethode.videotutorial;
      this.linksMethode.furtherEducation.forEach(education=> { education.link !==null ? education.link = environment.mediaPathURl + education.link:""});
      this.linksMethode.networking.forEach(network=> { network.link !==null ? network.link = environment.mediaPathURl + network.link:""});
      this.linksMethode.studentHelp.forEach(help=> { help.link !==null ? help.link = environment.mediaPathURl + help.link:""});
      this.linksMethode.proposalApproaches.forEach(aproach=> { aproach.link !==null ? aproach.link = environment.mediaPathURl + aproach.link:""});

    })
  }
  goToMethode(methodeId: number) {
    this.router.navigate(['/methode-info', methodeId]);
  }

}
