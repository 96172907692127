import { WorkExperiencesService } from './../../../shared/services/employees/work-experiences/work-experiences.service';
import { ResponsabilitiesService } from 'src/app/shared/services/employees/responsabilities/responsabilities.service';
import { DepartmentsService } from './../../../shared/services/employees/departments/departments.service';
import { EmployeeDTO } from '../dto/employee-dto';
import { Employee } from '../model/employee';
import { Department } from '../model/department';
import { Responsability } from '../model/responsability';
import { Workexperience } from '../model/workexperience';

export class EmployeeDTOToEmployeeMapper {

  public static map(employeeDTO: EmployeeDTO): Employee {
    let employee: Employee = new Employee();
    employee.id = employeeDTO.id;
    employee.email = employeeDTO.email;
    employee.department = new Department(employeeDTO.department, DepartmentsService.getDepartmentByIndex(employeeDTO.department));
    employee.responsability = new Responsability(employeeDTO.responsability, ResponsabilitiesService.getResponsabilityByIndex(employeeDTO.responsability));
    employee.workexperience = new Workexperience(employeeDTO.workexperience, WorkExperiencesService.getWorkExperienceByIndex(employeeDTO.workexperience));
    employee.feedbackStatus = employeeDTO.feedbackStatus;
    employee.emailSent = employeeDTO.emailSent;
    return employee;
  }
}
