import { Point } from './../../../core/services/survey/calculate-point/point';
export class PaintCloudPoint{
  color: string;
  degree: number;
  score: number;
  constructor( score:number ,degree:number, color: string){
    this.score = score;
    this.degree = degree;
    this.color = color;
  }
}
