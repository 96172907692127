import { TokenExpirationService } from './../../../core/services/auth-service/token-expiration/token-expiration.service';

import { CompanyDtoToCompanyMapper } from './../mapper/company-dto-to-company-mapper';
import { CompanyDTO } from 'src/app/modules/companies/dto/company-dto';
import { SearchCompaniesService } from './../../../core/services/companies/search/search-companies.service';
import { Company } from './../domain/company';
import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-display-companies',
  templateUrl: './display-companies.component.html',
  styleUrls: ['./display-companies.component.scss']
})
export class DisplayCompaniesComponent implements OnInit {
  companies = [];
  config: any;

  constructor(private searchCompaniesService: SearchCompaniesService,
    private spinnerService: Ng4LoadingSpinnerService,
    private tokenExpirationService: TokenExpirationService
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 20
    };
  }

  ngOnInit() {
    this.getCompanies();
    this.orderCompaniesByName();
  }

  pageChange(newPage: number) {
    this.config.currentPage = newPage;
  }

  private orderCompaniesByName() {
    this.companies.sort((beforeCompany, afterCompany) => beforeCompany.companyName.localeCompare(afterCompany.companyName));
  }
  onCompanyAdded(isAdded: boolean) {
    this.orderCompaniesByName();
  }
  onCompanyDeleted(deleteNumer: number) {
    this.removeCompanyFromList(deleteNumer);

  }
  private removeCompanyFromList(companyId: number) {
    let index = this.companies.findIndex(company => company.id === companyId);
    this.companies.splice(index, 1);
  }
  private getCompanies(): Array<Company> {
    let companies: Array<Company> = new Array();
    this.spinnerService.show();
    this.searchCompaniesService.getAllCompanies().subscribe(response => {
      let companiesDTO: Array<CompanyDTO> = response;
      companiesDTO.forEach(dto => {
        companies.push(CompanyDtoToCompanyMapper.map(dto));
      })
      this.companies = companiesDTO;
      this.spinnerService.hide();
    }, error => {
      this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
    });
    return companies;
  }


}
