import { FetchMethodoInfoService } from './../../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { FetchMethodeMainInfoDto } from './../../dto/fetch-methode-main-info-dto';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'methodo-description',
  templateUrl: './methodo-description.component.html',
  styleUrls: ['./methodo-description.component.scss']
})
export class MethodoDescriptionComponent implements OnInit {

  @Input() methodeId: number = 0;
  methode: FetchMethodeMainInfoDto = new FetchMethodeMainInfoDto();
  constructor(private fetchMethodoInfoService: FetchMethodoInfoService) { }

  ngOnInit() {
    this.fetchMethodoInfoService.getMehotodoMainInfo(this.methodeId).subscribe(response => {
      this.methode = response;
      (this.methode.imageUrl ==='' || this.methode.imageUrl === undefined)? this.methode.imageUrl = null: this.methode.imageUrl = environment.mediaPathURl+this.methode.imageUrl;
    });
  }

}
