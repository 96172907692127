import { FetchMethodeAssignmentsDto } from './../../dto/fetch-methode-assignments-dto';
import { FetchMethodoInfoService } from './../../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'methode-assignments',
  templateUrl: './methode-assignments.component.html',
  styleUrls: ['./methode-assignments.component.scss']
})
export class MethodeAssignmentsComponent implements OnInit {

  @Input() methodeId:number;
  public methodeAssignment:FetchMethodeAssignmentsDto = new FetchMethodeAssignmentsDto();
  constructor(private fetchMethodoInfoService: FetchMethodoInfoService) { }

  ngOnInit() {
    this.fetchMethodoInfoService.getMethodoAssignmentsInfo(this.methodeId).subscribe(response=>{
      this.methodeAssignment = response;
    });

  }

}
