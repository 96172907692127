export class SurveyEmployeeResponse{
  public surveyQuestionGroupId: number;
  public  surveyQuestionId: number;
  public response:number;
  constructor(surveyQuestionGroupId: number, surveyQuestionId: number, response:number){
    this.surveyQuestionGroupId = surveyQuestionGroupId;
    this.surveyQuestionId = surveyQuestionId;
    this.response = response;
  }
}
