import { AppModule } from './../app.module';
import { AppRoutingModule } from './../app-routing.module';
import { HeaderUserInfoComponent } from './components/layout/header-user-info/header-user-info.component';
import { HeaderHomeButtonComponent } from './components/layout/header-home-button/header-home-button.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MustMatch } from './validators/must-match.validator';
import { PrintButtonComponent } from './components/print-button/print-button.component';

@NgModule({
  declarations: [
    HeaderHomeButtonComponent,
    HeaderUserInfoComponent,
    PrintButtonComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule
  ],
  exports: [
    HeaderHomeButtonComponent,
    HeaderUserInfoComponent,
    PrintButtonComponent
  ]
})
export class SharedModule { }
