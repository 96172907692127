import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  public isValidToken(token:string):boolean{
    return this.checkToken(token);
  }
  private checkToken(token:string): boolean {
    if (!token){
      return false;
    }

    return new Date().getTime() < this.getTokenExpirationDate(token).getTime() ;
  }
  private getTokenExpirationDate(token:string): Date {
    let tokenObject: any = JSON.parse(atob(token));
    return new Date(tokenObject.token_expiration.replace(/\s/, 'T'));
  }
}

