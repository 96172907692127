import { FetchMethodoInfoService } from './../../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { Component, OnInit, Input } from '@angular/core';
import { CommentsAndRatingsDto } from '../../dto/comments-and-ratings-dto';

@Component({
  selector: 'comments-and-ratings',
  templateUrl: './comments-and-ratings.component.html',
  styleUrls: ['./comments-and-ratings.component.scss']
})
export class CommentsAndRatingsComponent implements OnInit {

  @Input() methodeId: number;
  comments: Array<CommentsAndRatingsDto> = new Array();
  constructor(private fetchMethodoInfoService: FetchMethodoInfoService) { }

  ngOnInit() {
    this.fetchMethodoInfoService.getMethodoCommentsInfo(this.methodeId).subscribe(response => {
      this.comments = response;
    })
  }

}
