import { DeleteCompanyRepository } from './../../../repositories/companies/delete/delete-company-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeleteCompanyService {

  constructor(private deleteCompanyRepository:DeleteCompanyRepository) { }

  deleteCompany(companyId: number):Observable<any>{
    return this.deleteCompanyRepository.deleteCompany(companyId);
  }
}
