import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-layout',
  templateUrl: './survey-layout.component.html'
})
export class SurveyLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
