import { MethodeToogleComponent } from './show-methodo-info/methode-toogle/methode-toogle.component';

import { MethodoDescriptionComponent } from './show-methodo-info/methodo-description/methodo-description.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowMethodosComponent } from './show-methodos/show-methodos.component';
import { SubtopicPopUpComponent } from './subtopic-pop-up/subtopic-pop-up.component';
import { ShowMethodoInfoComponent } from './show-methodo-info/show-methodo-info.component';
import { StrengthsWeaknessesComponent } from './show-methodo-info/strengths-weaknesses/strengths-weaknesses.component';
import { MethodeImplementationInfoComponent } from './show-methodo-info/methode-implementation-info/methode-implementation-info.component';
import { MethodeExecutionInfoComponent } from './show-methodo-info/methode-execution-info/methode-execution-info.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MethodePraxiExamplesInfoComponent } from './show-methodo-info/methode-praxi-examples-info/methode-praxi-examples-info.component';
import { MethodeLinksComponent } from './show-methodo-info/methode-links/methode-links.component';
import { MethodeAssignmentsComponent } from './show-methodo-info/methode-assignments/methode-assignments.component';
import { CommentsAndRatingsComponent } from './show-methodo-info/comments-and-ratings/comments-and-ratings.component';
import { LiteratureComponent } from './show-methodo-info/literature/literature.component';
import { MethodeKeywordsComponent } from './show-methodo-info/methode-keywords/methode-keywords.component';
import { MethodeInputInfoComponent } from './show-methodo-info/methode-input-info/methode-input-info.component';
import { MethodeOutputInfoComponent } from './show-methodo-info/methode-output-info/methode-output-info.component';
import { ListMethodosComponent } from './list-methodos/list-methodos.component';

@NgModule({
  declarations: [ShowMethodosComponent, SubtopicPopUpComponent, ShowMethodoInfoComponent, MethodeToogleComponent, MethodoDescriptionComponent, StrengthsWeaknessesComponent, MethodeImplementationInfoComponent, MethodeExecutionInfoComponent, MethodePraxiExamplesInfoComponent, MethodeLinksComponent, MethodeAssignmentsComponent, CommentsAndRatingsComponent, LiteratureComponent, MethodeKeywordsComponent, MethodeInputInfoComponent, MethodeOutputInfoComponent, ListMethodosComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[
    ShowMethodoInfoComponent,
    ShowMethodosComponent
  ]
})
export class MethodosModule { }
