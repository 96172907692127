import { AddCompanyDTO } from './../../../../modules/companies/dto/add-company-dto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AddCompanyRepository{
  constructor(private http:HttpClient){

  }
  addNewCompany( addCompanyDTO:AddCompanyDTO):Observable<any>{
    const url: string = environment.rootUrl + environment.endpoints.companies;
    let body: string = JSON.stringify(addCompanyDTO);
    return this.http.post(url, body);
  }
}
