import { Router } from '@angular/router';
import { FetchMethodoInfoService } from './../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { FetchMethodoDto } from './../dto/fetch-methodo-dto';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-methodos',
  templateUrl: './list-methodos.component.html',
  styleUrls: ['./list-methodos.component.scss']
})
export class ListMethodosComponent implements OnInit {

  fisrtList: Array<FetchMethodoDto> = new Array();
  secondList: Array<FetchMethodoDto> = new Array();
  thirdList:Array<FetchMethodoDto> = new Array();
  constructor(private router: Router, private fetchmethodoInfoService: FetchMethodoInfoService) { }



  ngOnInit() {
    this.fetchmethodoInfoService.getMethodosList().subscribe(response => {
      let methodos:Array<FetchMethodoDto> = response;
      let methodsPerLine = methodos.length/3;
      methodos.sort((x1,x2)=>x1.name.localeCompare(x2.name));
      for(let i:number = 0; i < methodos.length; i++){
        if(i > methodsPerLine * 2){
          this.thirdList.push(methodos[i]);
        }else if( i > methodsPerLine){
          this.secondList.push(methodos[i]);
        }else{
          this.fisrtList.push(methodos[i]);
        }
      }
    });
  }
  goToMethodos(methodoId: number) {
    this.router.navigate(['/methode-info', methodoId]);
  }
}
