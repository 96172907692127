import { Router } from '@angular/router';
import { AuthService } from './../auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenExpirationService {

  private readonly TOKEN_EXPIRED:string = "JWT Token";
  constructor(private authService: AuthService, private router: Router) { }

  public checkIfTokenIsExpiredAndLogOut(code:number, message:string){
    if(code === 401 && message.indexOf(this.TOKEN_EXPIRED) >= 0){
      this.authService.logout();
      this.router.navigate(['/login', "Sitzung ist abgelaufen"]);
    }

  }
}
