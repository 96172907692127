import { MessageTypeEnum } from 'src/app/core/message-section/message-type-enum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Message } from 'src/app/core/message-section/message';
import { PasswordService } from 'src/app/core/services/password-service/password.service';
import { ValidationMessageService } from 'src/app/shared/services/validation-message-service/validation-message.service';

@Component({
  selector: 'app-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss']
})
export class RequestPasswordComponent implements OnInit {

  requestPasswordForm: FormGroup;
  private validationMessages: any;
  message: Message;
  private targetEmail: string;

  constructor(private formBuilder: FormBuilder, private validationMessageService: ValidationMessageService, private passwordService: PasswordService) { }

  ngOnInit() {
    this.requestPasswordForm = this.createFormGroup();
    this.validationMessages = this.createValidationMessages();
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group(
      {
        email: ["", [Validators.required, Validators.email]]
      }
    );
  }

  private createValidationMessages(): any {
    return (
      {
        email: {
          required: "Bitte geben Sie eine E-Mail Adresse ein!",
          email: "Ungültige E-Mail Adresse."
        }
      });
  }

  send() {
    this.message = null;
    this.requestPasswordForm.reset(this.requestPasswordForm.value);
    if (this.requestPasswordForm.invalid) {
      this.message = new Message(MessageTypeEnum.ERROR, this.mapErrorMessages());
    }
    else {
      this.targetEmail = this.requestPasswordForm.value.email;
      this.passwordService.requestPasswordChange(this.targetEmail).subscribe(
        response => {
          this.createSuccessMessage();
        },
        error => {
          this.createSuccessMessage();
        }
      );
    }
  }

  private createSuccessMessage() {
    let messageText: string = "Es wurde eine E-Mail mit einem Link zum Ändern des Passwortes an die angegebene E-Mail-Adresse gesendet: " + this.targetEmail;
    this.message = new Message(MessageTypeEnum.SUCCESS, [messageText]);
  }

  private mapErrorMessages(): Array<string> {
    return this.validationMessageService.mapErrorMessages(this.requestPasswordForm, this.validationMessages);
  }

}
