import { Observable } from 'rxjs';
import { FetchMethodosInfoRepository } from './../../../repositories/methodos/fetch-methodos-info-repository';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FetchMethodoInfoService {

  constructor(private fetchMethodosInfoRepository: FetchMethodosInfoRepository) { }

  getMehotodoMainInfo(methodoId:number):Observable<any>{
    return this.fetchMethodosInfoRepository.getMehotodoMainInfo(methodoId);
  }

  getMehotodoStrengthsAndWeaknesses(methodoId:number):Observable<any>{
    return this.fetchMethodosInfoRepository.getMehotodoStrengthsAndWeaknesses(methodoId);
  }

  getMethodoImplementationsInfo(methodoId:number):Observable<any>{
    return this.fetchMethodosInfoRepository.getMethodoImplementationsInfo(methodoId);
  }
  getMethodoPraxiExamplesInfo(methodoId:number):Observable<any>{
    return this.fetchMethodosInfoRepository.getMethodoPraxiExamplesInfo(methodoId);
  }
  getMethodoLinksInfo(methodoId:number):Observable<any>{
    return this.fetchMethodosInfoRepository.getMethodoLinksInfo(methodoId);
  }
  getMethododevelopmentsInfo(methodoId:number):Observable<any>{
    return this.fetchMethodosInfoRepository.getMethododevelopmentsInfo(methodoId);
  }
  getMethodoAssignmentsInfo(methodoId:number):Observable<any>{
    return this.fetchMethodosInfoRepository.getMethodoAssignmentsInfo(methodoId);
  }
  getMethodoCommentsInfo(methodoId:number):Observable<any>{
    return this.fetchMethodosInfoRepository.getMethodoCommentsInfo(methodoId);
  }
  getMethodoLiteratureInfo(methodoId:number):Observable<any>{
    return this.fetchMethodosInfoRepository.getMethodoLiteratureInfo(methodoId);
  }
  getMethodoKeywords(methodoId:number):Observable<any>{
    return this.fetchMethodosInfoRepository.getMethodoKeywords(methodoId);
  }
  getMethodeInputInfo(methodoId:number):Observable<any>{
    return this.fetchMethodosInfoRepository.getMethodeInputInfo(methodoId);
  }
  getMethodeOutputInfo(methodoId:number):Observable<any>{
    return this.fetchMethodosInfoRepository.getMethodeOutputInfo(methodoId);
  }
  getMethodosList():Observable<any>{
    return this.fetchMethodosInfoRepository.getMethodosList();
  }
}
