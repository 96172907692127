import { FetchMethodeImplementationsDto } from '../../dto/fetch-methode-implementations-dto';
import { FetchMethodoInfoService } from '../../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'methode-implementation-info',
  templateUrl: './methode-implementation-info.component.html',
  styleUrls: ['./methode-implementation-info.component.scss']
})
export class MethodeImplementationInfoComponent implements OnInit {

  @Input() methodeId: number;
  methode: FetchMethodeImplementationsDto = new FetchMethodeImplementationsDto();
  implementations: Array<string> = new Array();
  constructor(private fetchMethodoInfoService: FetchMethodoInfoService) { }

  ngOnInit() {
    this.fetchMethodoInfoService.getMethodoImplementationsInfo(this.methodeId).subscribe(response => {
      this.methode = response;
      this.splitImplementations();
    })


  }
  private splitImplementations() {
    if (this.methode.implementation) {
      this.implementations = this.methode.implementation.split("\n");
    }
  }

}
