import { DeleteEmployeeReposiroty } from './../../../repositories/employees/delete-employee/delete-employee-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeleteEmployeeService {

  constructor(private deleteEmployeeReposiroty:DeleteEmployeeReposiroty) { }

  deleteEmployee(employeeId:number, companyId:number):Observable<any>{
    return this.deleteEmployeeReposiroty.deleteEmployee(employeeId, companyId);
  }
}
