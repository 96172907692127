
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {
  private static departments:Array<string>= ['Arbeitsvorbereitung', 'Entwicklung',
  'Fertigung', 'Management', 'Qualitätssicherung','Vertrieb','Personal', 'Betriebsrat', 'Sonstiges'];

  static getDepartmentByIndex(index:number):string{
    if(index > this.departments.length || index <= 0){
      throw new Error("Illegal argument in get BereichAbteilung");
    }
    return this.departments[index-1];
  }
  static getDepartmentSize():number{
    return this.departments.length;
  }
  static getAllDepartments():Array<string>{
    return this.departments;
  }
  static getIdFromDepartment(departmentToSearch:string):number{
    return this.departments.findIndex(departmen => departmen===departmentToSearch)+1;
  }
};

