export class LoginUser {

  username: string;
  password: string;

  constructor(init?: Partial<LoginUser>) {
    Object.assign(this, init);
  }


}
