import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-footer',
  templateUrl: './survey-footer.component.html',
  styleUrls: ['./survey-footer.component.scss']
})
export class SurveyFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
