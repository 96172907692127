import { FetchSurveyRepository } from './../../../repositories/survey/fetch-survey-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FetchCompanyResponsesService {

  constructor(private fetchSurveyRepository: FetchSurveyRepository) { }

  public getIdealAverageFromCompany(companyId: number):Observable<any>{
    return this.fetchSurveyRepository.getIdealAverageFromCompany(companyId);
  }

  public getAllResponsesFromCompany(companyId: number):Observable<any>{
    return this.fetchSurveyRepository.getAllResponsesFromCompany(companyId);
  }

  public getTotalAverageFromCompany(companyId:number):Observable<any>{
    return this.fetchSurveyRepository.getTotalAverageFromCompany(companyId);
  }
}
