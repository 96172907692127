export class FetchmethodeDevelopmentsDto {
  public timeEffort: string; public
  public preparationTime: string;
  public trainingEffort: string;
  public teamSize: string;
  public moderator: number;
  public materials: Array<string> = new Array();
  public resources: Array<ResourcesMethodeDto> = new Array();
  public warning: string;
  constructor() { }

}
export class ResourcesMethodeDto {
  public title: string;
  public description: string;
  public attachment: string;
  constructor() { }
}
