import { Employee } from './../model/employee';
import { Component, OnInit, Input, Output, EventEmitter, AfterContentInit, OnChanges } from '@angular/core';

@Component({
  selector: 'display-employees',
  templateUrl: './display-employees.component.html',
  styleUrls: ['./display-employees.component.scss']
})
export class DisplayEmployeesComponent implements OnChanges {
  @Input() showEmployee: boolean = true;
  @Input()private employees:Array<Employee> = new Array();
  @Input()private companyId:number;
  @Input() companyName:string ="";

  @Output() isCompanyChanged = new EventEmitter<any>();
  constructor() { }

  ngOnChanges() {
    this.sortEmployees();
  }
  private onEmployeeAdded(isAdded: boolean) {
    this.sortEmployees();
    this.isCompanyChanged.emit(true);
  }
  private sortEmployees(){
    this.employees.sort(
      (employee1, employee2) => {
        if (employee1.email > employee2.email) return 1;
        if (employee1.email < employee2.email) return -1;
      });
  }

  private onEmployeeDeleted(deletedEmployeeId:number){
    this.removeEmployeeFromList(deletedEmployeeId);
    this.sortEmployees();
    this.isCompanyChanged.emit(true);
  }
  private removeEmployeeFromList(employeeId:number){
    let index = this.employees.findIndex(employee=>employee.id===employeeId);
    this.employees.splice(index, 1);
  }
}
