import { TokenExpirationService } from './../../../core/services/auth-service/token-expiration/token-expiration.service';
import { SurveyEmployeeResponseDtoToSurveyEmployeeResponseMapper } from './../mappers/survey-employee-response-dto-to-survey-employee-response-mapper';
import { SurveyEmployeeResponse } from './../model/survey-employee-response';
import { SurveyQuestion } from './../model/survey-question';
import { MessageTypeEnum } from './../../../core/message-section/message-type-enum';
import { Message } from 'src/app/core/message-section/message';
import { FetchSurveyQuestionsService } from './../../../core/services/survey/fetch-survey-questions/fetch-survey-questions.service';
import { FetchEmployeeResponsesService } from '../../../core/services/survey/fetch-employee-responses/fetch-employee-responses.service';
import { GroupSurveyCircleModel } from './../model/group-survey-circle-model';
import { FetchSurveyCircleGroupsService } from './../../../core/services/survey/fetch-survey-circle-groups/fetch-survey-circle-groups.service';
import { GroupAverage } from './../model/group-average';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PaintGroupAveragesLine } from '../model/paint-group-averages-line';
import { SurveyDisplay } from 'src/app/core/services/survey/survey-enum';
import { SurveyResponse } from '../model/survey-response';

@Component({
  selector: 'app-employee-feedback',
  templateUrl: './employee-feedback.component.html',
  styleUrls: ['./employee-feedback.component.scss']
})
export class EmployeeFeedbackComponent implements OnInit {
  employeeId: number;
  readonly left: SurveyDisplay = SurveyDisplay.LEFT;
  readonly right: SurveyDisplay = SurveyDisplay.RIGHT;
  employee: string = "Federico";
  company: string = "Wata";
  showLeft: boolean = false;
  showRight: boolean = false;
  showMethodos: boolean = false;
  message: Message;
  actualGroupId: number = 0;
  actualGroupName: string = "Survey Response Group Id";
  allQuestions: Array<SurveyResponse> = new Array();
  actualQuestions: Array<SurveyResponse> = new Array();
  responsesGroupAverages: Array<PaintGroupAveragesLine> = new Array();
  constructor(private route: ActivatedRoute, private fetchResponsesService: FetchEmployeeResponsesService, private fetchSurveyQuestionsService: FetchSurveyQuestionsService, private tokenExpirationService: TokenExpirationService) { }

  ngOnInit() {
    this.takeEmployeeIdFromParams();
    this.loadEmployeeFeedback();

  }
  private takeEmployeeIdFromParams() {
    this.route.params.subscribe(params => {
      this.employeeId = params['employeeId']
      this.employee = params['employee']
      this.company = params['company']
    });
  }
  private loadEmployeeFeedback() {
    this.getAllQuestionsFromBackend();

  }
  private getAllEmployeeResponsesFromBackend() {

    this.fetchResponsesService.getEmployeeResponses(this.employeeId).subscribe(response => {
      let responses: Array<SurveyEmployeeResponse> = response;
      let employeeResponses: Array<SurveyEmployeeResponse> = new Array();
      responses.forEach(surveyResponse => {
        employeeResponses.push(SurveyEmployeeResponseDtoToSurveyEmployeeResponseMapper.map(surveyResponse));
      });
      this.responsesGroupAverages.splice(0, this.responsesGroupAverages.length);
      this.responsesGroupAverages.push(new PaintGroupAveragesLine(this.calculateAverageFromEmployee(employeeResponses, this.allQuestions), "red", "(1)"));
    }, error => {
      this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
      this.message = new Message(MessageTypeEnum.ERROR, ["Ein unerwarteter Fehler ist aufgetreten."]);
    });
  }
  private getAllQuestionsFromBackend() {
    this.fetchSurveyQuestionsService.getSurveyQuestions().subscribe(response => {
      let questions: Array<SurveyQuestion> = response;
      questions.forEach(surveyQuestion => {
        this.allQuestions.push(new SurveyResponse(surveyQuestion.surveyQuestionId, surveyQuestion.question, surveyQuestion.surveyQuestionGroupId, null));
      });
      this.getAllEmployeeResponsesFromBackend();

    }, error => {
      this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
      this.message = new Message(MessageTypeEnum.ERROR, ["Ein unerwarteter Fehler ist aufgetreten."]);
    });
  }
  private calculateAverageFromEmployee(employeeResponses: Array<SurveyEmployeeResponse>, surveyQuestions: Array<SurveyResponse>): Array<GroupAverage> {
    let averages: Array<GroupAverage> = new Array();
    surveyQuestions.sort((surveyBefore, surveyAfter) => { return surveyBefore.questionGroup - surveyAfter.questionGroup });
    this.getDifferentsGroupId(employeeResponses).forEach(groupId => {
      let employeeResponseFromSurveyGroupId: Array<SurveyEmployeeResponse> = employeeResponses.filter(employee => employee.surveyQuestionGroupId === groupId);
      let sum: number = 0;
      employeeResponseFromSurveyGroupId.forEach(x => { sum += x.response });
      averages.push(new GroupAverage(groupId, sum/employeeResponseFromSurveyGroupId.length, FetchSurveyCircleGroupsService.getDegreeFromGroupId(groupId, 0.5)))
    })

    return averages;
  }
  private getDifferentsGroupId(surveyQuestions: Array<SurveyEmployeeResponse>): Array<number> {
    let groups: Array<number> = new Array();
    let actualGroup: number = 0;
    surveyQuestions.forEach((survey) => {
      if (survey.surveyQuestionGroupId !== actualGroup) {
        groups.push(survey.surveyQuestionGroupId);
        actualGroup = survey.surveyQuestionGroupId;
      }
    })
    return groups;
  }
  onGroupClicked(group: GroupSurveyCircleModel) {
    this.showMethodos = false;
    FetchSurveyCircleGroupsService.hideAllLinesExceptFromGroupById(group.id);
    this.actualQuestions = this.allQuestions.filter(question => { return question.questionGroup === group.id });
    this.actualGroupName = group.groupName;
    this.checkShowDisplay(group.display);

  }
  onInformationClicked(idGroup: number) {
    this.actualGroupId = idGroup;
    this.showMethodos = true;
    this.actualGroupName = FetchSurveyCircleGroupsService.getGroupNameByGroupID(idGroup);
    FetchSurveyCircleGroupsService.hideAllLinesExceptFromGroupById(idGroup);
    this.checkShowDisplay(FetchSurveyCircleGroupsService.getDisplayByGroupId(idGroup));
  }

  private checkShowDisplay(display: SurveyDisplay) {
    if (display === SurveyDisplay.LEFT) {
      this.showInLeft();
    }
    else {
      this.showInRight();
    }
  }
  private showInLeft() {
    this.showLeft = true;
    this.showRight = false;
    window.scrollTo(0, 0);
  }
  private showInRight() {
    this.showLeft = false;
    this.showRight = true;
    window.scrollTo(1500, 0);
  }
  private closeQuestion(survey: SurveyDisplay) {
    if (survey === SurveyDisplay.LEFT) {
      this.showLeft = false;
    } else {
      this.showRight = false;
    }
    FetchSurveyCircleGroupsService.hideALl();
  }

}
