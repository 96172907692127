import { MethodoDtoToMethodoMapper } from './../mapper/methodo-dto-to-methodo.mapper';
import { MethodoDto } from './../dto/methodo-dto';
import { FetchSubtopicService } from '../../../core/services/methodos/fetch-subtopics/fetch-subtopic.service';
import { FetchMethodoDtoToFetchMethodoMapper } from './../mapper/fetch-methodo-dto-to-fetch-methodo-mapper';
import { FetchMethodoDto } from './../dto/fetch-methodo-dto';
import { FetchTopicService } from './../../../core/services/methodos/fetch-topic/fetch-topic.service';
import { Methodo } from './../modal/methodo';
import { SimpleModalService } from 'ngx-simple-modal';
import { FetchMethodo } from './../modal/fetch-methodo';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { SurveyDisplay } from 'src/app/core/services/survey/survey-enum';

@Component({
  selector: 'show-methodos',
  templateUrl: './show-methodos.component.html',
  styleUrls: ['./show-methodos.component.scss']
})
export class ShowMethodosComponent implements OnInit {
  @Input() groupName: string;
  @Input() groupId: number;
  @Input() display: SurveyDisplay;
  @Output() closeMethodo = new EventEmitter<SurveyDisplay>();

  areMethodos: boolean = false;
  actualMethodo: Methodo;
  actualSelected: number;
  methodos: Array<FetchMethodo> = new Array();
  constructor(private simpleModalService: SimpleModalService, private fetchTopicService: FetchTopicService, private fetchSubTopicService: FetchSubtopicService) { }

  ngOnInit() {
    this.actualMethodo = new Methodo("Kreative 1 Problemlösungsiddeen aug Grundlage der gedanklichen Leistung der Tllnehmer", 4, 4, 3, "Bis 10", ["Besonders für wenig Methodenerfahrene geeigent", "WARNING2"]);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.fetchTopicService.fetchMethodosByGroupId(this.groupId).subscribe(response => {
      let methodosDTO: Array<FetchMethodoDto> = response;
      this.methodos = new Array();
      methodosDTO.forEach(dto => {
        this.methodos.push(FetchMethodoDtoToFetchMethodoMapper.map(dto));
      });
      this.areMethodos = (this.methodos.length > 0);
    });
  }

  showMethodo(id: number) {
    this.fetchSubTopicService.getSubtopicsByMethodo(id).subscribe(response => {
      let dto: MethodoDto = response;
      this.actualMethodo = MethodoDtoToMethodoMapper.map(dto);
    })
    this.actualSelected = id;
    this.getPositionFromPrincipal();

  }
  closeActualMethodo(isClossed: boolean) {
    this.actualSelected = null;
    document.getElementById('pop-up-methodos').style.display = "none";
  }
  closeMethodos() {
    this.actualSelected = null;
    this.closeMethodo.emit(this.display);
    document.getElementById('pop-up-methodos').style.display = "none";
  }
  private getPositionFromPrincipal() {
    let showMethodo: HTMLElement = document.getElementById("show_methodos_" + this.display);
    let popUpElement: HTMLElement = document.getElementById('pop-up-methodos');

    let width: number = showMethodo.getBoundingClientRect().width;
    if (this.display === SurveyDisplay.LEFT) {
      let left: number = showMethodo.getBoundingClientRect().left;
      left <= 0 ? popUpElement.style.left = (width + 35) + "px" : popUpElement.style.left = (left + width + 5) + "px";
    } else {
      let position: number = ((920 * window.innerWidth) / 1615);
      window.innerWidth <= 1615 ? popUpElement.style.left = "925px" : popUpElement.style.left = (position - 18) + "px";
    }
    popUpElement.style.top = (showMethodo.getBoundingClientRect().top >= 221) ? showMethodo.getBoundingClientRect().top + "px" : "221px";
    popUpElement.style.display = "inline";
  }
}
