import { Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Methodo } from '../modal/methodo';

@Component({
  selector: 'subtopic-pop-up',
  templateUrl: './subtopic-pop-up.component.html',
  styleUrls: ['./subtopic-pop-up.component.scss']
})
export class SubtopicPopUpComponent implements OnInit {

  @Output() closeMethodo = new EventEmitter<boolean>();
  @Input() methodo:Methodo;
  @Input() methodoId:number;
  constructor(private router: Router) { }

  ngOnInit() {
  }
  close(){
    this.closeMethodo.emit(true);
  }
  openMethodoDisplay(methodoId:number){
    this.router.navigate(['/methode-info', methodoId,]);
  }
}
