import { SaveEmployeeSurveyQuestionsService } from './../../../core/services/survey/save-employee-survey-questions/save-employee-survey-question.service';
import { SurveyDisplay } from 'src/app/core/services/survey/survey-enum';
import { SaveResponse } from './../model/save-response';

import { SurveyResponseDtoToSurveyResponse } from './../mappers/survey-response-dto-to-survey-response';
import { GroupAverage } from '../model/group-average';
import { SurveyResponse } from './../model/survey-response';
import { MessageTypeEnum } from 'src/app/core/message-section/message-type-enum';
import { Message } from 'src/app/core/message-section/message';
import { TokenService } from './../../../shared/services/token/token.service';
import { ActivatedRoute } from '@angular/router';
import { GroupSurveyCircleModel } from './../model/group-survey-circle-model';
import { Component, OnInit } from '@angular/core';
import { FetchSurveyCircleGroupsService } from 'src/app/core/services/survey/fetch-survey-circle-groups/fetch-survey-circle-groups.service';
import { SurveyResponseDto } from '../dto/survey-response-dto';
import { PaintGroupAveragesLine } from '../model/paint-group-averages-line';
import { FetchEmployeeSurveyService } from 'src/app/core/services/survey/fetch-employee-survey/fetch-employee-survey.service';

@Component({
  selector: 'app-feedback-display',
  templateUrl: './feedback-display.component.html',
  styleUrls: ['./feedback-display.component.scss']
})
export class FeedbackDisplayComponent implements OnInit {

  constructor(private route: ActivatedRoute, private fetchEmployeeSurveyService: FetchEmployeeSurveyService, private tokenService: TokenService, private questionService: SaveEmployeeSurveyQuestionsService) { }

  readonly left: SurveyDisplay = SurveyDisplay.LEFT;
  readonly right: SurveyDisplay = SurveyDisplay.RIGHT;
  showLeft: boolean = false;
  showRight: boolean = false;
  showMethodos: boolean = false;
  private token: string;
  changed: any;
  message: Message;
  surveys: Array<SurveyResponse> = new Array();
  actualQuestions: Array<SurveyResponse> = new Array();
  actualGroupName: string = "";
  actualGroupId: number = 0;
  responsesGroupAverages: Array<PaintGroupAveragesLine> = new Array();
  showCircle: boolean = true;

  ngOnInit() {
    this.message = null;
    this.takeTokenFromURL();
    this.getResponsesFromBackend();
  }
  onGroupClicked(group: GroupSurveyCircleModel) {
    this.showMethodos = false;
    FetchSurveyCircleGroupsService.hideAllLinesExceptFromGroupById(group.id);
    this.actualQuestions = this.getQuestionsFromGroup(group.id)
    this.actualGroupName = group.groupName;
    this.actualGroupId = group.id;
    this.checkShowDisplay(group.display);
  }

  private checkShowDisplay(display: SurveyDisplay) {
    if (this.checkTokenExpirationDate()) {
      if (display === SurveyDisplay.LEFT) {
        this.showInLeft();
      }
      else {
        this.showInRight();
      }
    }
    else {
      this.message = new Message(MessageTypeEnum.ERROR, ["Der Link ist abgelaufen."]);
      this.showCircle = false;
    }
  }

  onInformation(idGroup: number) {
    this.showMethodos = true;
    this.actualGroupId = idGroup;
    this.actualGroupName = FetchSurveyCircleGroupsService.getGroupNameByGroupID(idGroup);
    FetchSurveyCircleGroupsService.hideAllLinesExceptFromGroupById(idGroup);
    this.checkShowDisplay(FetchSurveyCircleGroupsService.getDisplayByGroupId(idGroup));
  }
  saveNewQuestion(question: SurveyResponse) {
    let response: SaveResponse = new SaveResponse(this.token, question.questionId, question.response);
    this.questionService.saveQuestion(response).subscribe(response => {
      this.updateCircle(question);
      this.changed = new Boolean(true);
    }, error => {
      this.message = new Message(MessageTypeEnum.ERROR, ["Es ist ein unerwarteter Fehler beim Speichern der Antwort aufgetreten"]);
    });

  }
  updateQuestion(question: SurveyResponse) {
    let response: SaveResponse = new SaveResponse(this.token, question.questionId, question.response);
    this.questionService.updateQuestion(response).subscribe(response => {
      this.updateCircle(question);
      this.changed = new Boolean(true);
    }, error => {
      this.message = new Message(MessageTypeEnum.ERROR, ["Es ist ein unerwarteter Fehler beim Speichern der Antwort aufgetreten"]);
    });
  }
  private takeTokenFromURL() {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
    });

  }
  private updateCircle(response: SurveyResponse) {
    this.responsesGroupAverages.splice(0, this.responsesGroupAverages.length);
    this.surveys.forEach(survey => {
      if (survey.questionId === response.questionId) {
        survey.response = response.response;
      }
    });
    let updatedAverages: Array<GroupAverage> = this.calculatePointsToPaint(this.surveys.filter(response => response.response !== null))
    this.responsesGroupAverages.push(new PaintGroupAveragesLine(updatedAverages, "red", null));
  }
  private getResponsesFromBackend() {
    this.message = null;
    try {
      if (this.checkTokenExpirationDate()) {
        this.getSurveyFromBackendAndCalculatePoints();
      } else {
        this.message = new Message(MessageTypeEnum.ERROR, ["Der Link ist abgelaufen."]);
        this.showCircle = false;
      }
    } catch{
      this.message = new Message(MessageTypeEnum.ERROR, ["Der Link ist nicht gültig."]);
      this.showCircle = false;
    }
  }
  private checkTokenExpirationDate(): boolean {
    return this.tokenService.isValidToken(this.token);

  }
  private getSurveyFromBackendAndCalculatePoints() {
    this.surveys.splice(0, this.surveys.length);
    this.fetchEmployeeSurveyService.getSurveyFromEmployee(this.token).subscribe(response => {

      let surveysResponsesDTO: Array<SurveyResponseDto> = response;
      surveysResponsesDTO.forEach(responseDTO => {
        this.surveys.push(SurveyResponseDtoToSurveyResponse.map(responseDTO));
      });
      let averages: Array<GroupAverage> = this.calculatePointsToPaint(this.surveys.filter(response => response.response !== null))
      this.responsesGroupAverages.push(new PaintGroupAveragesLine(averages, "red", null));

    }, error => {
      if (error.status >= 400 && error.status < 500) {
        if (error.status === 401) {
          this.message = new Message(MessageTypeEnum.ERROR, ["Der Link ist abgelaufen"]);
        } else if (error.status === 404) {
          this.message = new Message(MessageTypeEnum.ERROR, ["Der Link ist nicht gültig."]);
        } else {
          this.message = new Message(MessageTypeEnum.ERROR, ["Ein unerwarteter Fehler ist aufgetreten."]);
        }
      }
      else if (error.status >= 500) {
        this.message = new Message(MessageTypeEnum.ERROR, ["Bei der Kommunikation mit dem Server ist ein Fehler aufgetreten!"]);
      }
      else {
        this.message = new Message(MessageTypeEnum.ERROR, ["Es konnte keine Verbindung zum Server hergestellt werden."]);
      }
    })
  }
  private calculatePointsToPaint(employeeResponses: Array<SurveyResponse>): Array<GroupAverage> {
    let averages: Array<GroupAverage> = new Array();
    this.responsesGroupAverages.splice(0, this.responsesGroupAverages.length);
    this.getDifferentsGroupId(employeeResponses).forEach(groupId => {
      let employeeResponseFromSurveyGroupId: Array<SurveyResponse> = employeeResponses.filter(employee => employee.questionGroup === groupId);
      let sum: number = 0;
      employeeResponseFromSurveyGroupId.forEach(x => { sum += x.response });
      averages.push(new GroupAverage(groupId, sum / employeeResponseFromSurveyGroupId.length, FetchSurveyCircleGroupsService.getDegreeFromGroupId(groupId, 0.5)))
    })

    return averages;
  }
  private getDifferentsGroupId(surveyResponses: Array<SurveyResponse>): Array<number> {
    let groups: Array<number> = new Array();
    let actualGroup: number = 0;
    surveyResponses.forEach((survey) => {
      if (survey.questionGroup !== actualGroup) {
        groups.push(survey.questionGroup);
        actualGroup = survey.questionGroup;
      }
    })
    return groups;
  }
  private closeQuestion(survey: SurveyDisplay) {
    if (survey === SurveyDisplay.LEFT) {
      this.showLeft = false;
    } else {
      this.showRight = false;
    }
    FetchSurveyCircleGroupsService.hideALl();
  }
  private showInLeft() {
    this.showLeft = true;
    this.showRight = false;
    window.scrollTo(0, 0);
  }
  private showInRight() {
    this.showLeft = false;
    this.showRight = true;
    window.scrollTo(1500, 0);
  }
  private getQuestionsFromGroup(groupId: number): Array<SurveyResponse> {
    return this.surveys.filter(survey => survey.questionGroup == groupId);
  }
}
