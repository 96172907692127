
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MethodosRepository{
  constructor(private http: HttpClient){

  }
  getMehotodoByGroupId(groupId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+environment.endpoints.surveyquestiongroup+"/"+groupId;
    return this.http.get(url);
  }


  getSubtopicByMethodoId(methodoId:number):Observable<any>{
    const url: string = environment.rootUrl  + environment.endpoints.methodos+"/"+methodoId+"/"+environment.endpoints.summary;
    return this.http.get(url);
  }
}
