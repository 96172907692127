import { FetchMethodoInfoService } from './../../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { FetchMethodeKeywordsDTO } from './../../dto/fetch-methode-keywords-dto';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'methode-keywords',
  templateUrl: './methode-keywords.component.html',
  styleUrls: ['./methode-keywords.component.scss']
})
export class MethodeKeywordsComponent implements OnInit {

  @Input() methodeId: number;
  keywords: Array<FetchMethodeKeywordsDTO> = new Array();
  constructor(private fetchMethodoInfoService: FetchMethodoInfoService) { }

  ngOnInit() {
    this.fetchMethodoInfoService.getMethodoKeywords(this.methodeId).subscribe(
      response => { this.keywords = response; },
    );
  }

}
