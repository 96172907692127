import { SaveResponse } from '../../../../modules/survey-circle/model/save-response';
import { SaveResponseToSaveResponseDtoMapper } from '../../../../modules/survey-circle/mappers/save-response-to-save-response-dto-mapper';
import { SaveQuestionRepository } from '../../../repositories/survey/save-question/save-question-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SaveEmployeeSurveyQuestionsService {

  constructor(private saveQuestionRepository: SaveQuestionRepository) { }

  saveQuestion(saveResponse:SaveResponse):Observable<any>{
    return this.saveQuestionRepository.saveNewQuestion(SaveResponseToSaveResponseDtoMapper.map(saveResponse))
  }
  updateQuestion(updateResponse:SaveResponse){
    return this.saveQuestionRepository.updateQuestion(SaveResponseToSaveResponseDtoMapper.map(updateResponse));
  }
}
