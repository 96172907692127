import { TokenService } from './../../../../shared/services/token/token.service';
import { UpdatePassword } from './../../model/update-password';
import { UpdatePasswordMapper } from './../../mapper/update-password-mapper';
import { MessageTypeEnum } from 'src/app/core/message-section/message-type-enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Message } from 'src/app/core/message-section/message';
import { PasswordService } from 'src/app/core/services/password-service/password.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from 'src/app/shared/validators/must-match.validator';
import { _document } from '@angular/platform-browser/src/browser';
import { ValidationMessageService } from 'src/app/shared/services/validation-message-service/validation-message.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  message: Message;
  updatePasswordForm: FormGroup;
  private validationMessages: any;
  private token: string;
  private tokenExpirationDate: Date;
  validToken: boolean;

  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private validationMessageService: ValidationMessageService,
    private passwordService: PasswordService, private router: Router, private tokenService: TokenService) { }

  ngOnInit() {
    this.checkTokenFromUrl();

    this.updatePasswordForm = this.createFormGroup();
    this.validationMessages = this.createValidationMessages();
  }
  private checkTokenFromUrl() {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
    });
    try {
      this.validToken = this.tokenService.isValidToken(this.token);
      if (this.validToken === false) {
        this.message = new Message(MessageTypeEnum.ERROR, ["Der Link ist abgelaufen."]);
      }
    } catch{
      this.message = new Message(MessageTypeEnum.ERROR, ["Der Link ist nicht gültig."]);
    }
  }
  private createFormGroup(): FormGroup {
    return this.formBuilder.group(
      {
        password: ["", [Validators.required, Validators.minLength(8)]],
        passwordRepeat: ["", [Validators.required]]
      },
      {
        validator: MustMatch('password', 'passwordRepeat')
      }

    );
  }

  private createValidationMessages(): any {
    return (
      {
        password: {
          required: "Bitte geben Sie ein neues Passwort ein!",
          minlength: "Das Passwort muss aus mindestens 8 Zeichen bestehen."
        },
        passwordRepeat: {
          required: "Bitte wiederholen Sie das neue Passwort!",
          mustMatch: "Das neue Passwort und die Wiederholung des neuen Passwortes stimmen nicht überein."
        }
      });
  }



  private updatePassword() {

    this.message = null;
    this.updatePasswordForm.reset(this.updatePasswordForm.value);
    if (this.updatePasswordForm.invalid) {
      this.message = new Message(MessageTypeEnum.ERROR, this.mapErrorMessages());
    }
    else {
      this.validToken = this.tokenService.isValidToken(this.token);
      if (this.validToken) {
        let updatePassword = new UpdatePassword(this.updatePasswordForm.value);
        updatePassword.token = this.token;
        this.passwordService.updatePassword(UpdatePasswordMapper.mapToDTO(updatePassword)).subscribe(
          response => {
            this.router.navigate(['/login']);
          },
          error => {
            if (error.status >= 400 && error.status < 500) {
              this.validToken = false;
              this.message = new Message(MessageTypeEnum.ERROR, ["Der Link ist abgelaufen. Bitte beantragen Sie die Passwortzurücksetzung erneut."]);
            }
            else if (error.status >= 500) {
              this.message = new Message(MessageTypeEnum.ERROR, ["Bei der Kommunikation mit dem Server ist ein Fehler aufgetreten!"]);
            }
            else {
              this.message = new Message(MessageTypeEnum.ERROR, ["Es konnte keine Verbindung zum Server hergestellt werden."]);
            }
          }
        );
      }
    }
  }

  private mapErrorMessages(): Array<string> {
    return this.validationMessageService.mapErrorMessages(this.updatePasswordForm, this.validationMessages);
  }

}


