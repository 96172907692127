import { TokenExpirationService } from './../../../../core/services/auth-service/token-expiration/token-expiration.service';
import { Router } from '@angular/router';
import { SendEmailService } from './../../../../core/services/send-email/send-email.service';
import { DeleteEmployeeService } from './../../../../core/services/employees/delete-employee/delete-employee.service';
import { DeleteEmployeeComponent } from './../../delete-employee/delete-employee.component';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Employee } from '../../model/employee';
import { SimpleModalService } from 'ngx-simple-modal';


@Component({
  selector: 'display-employee',
  templateUrl: './display-employee.component.html',
  styleUrls: ['./display-employee.component.scss']
})
export class DisplayEmployeeComponent implements OnInit {
  @Input() companyId: number;
  @Input() employee: Employee;
  @Output() employeeDeleted = new EventEmitter<number>();
  @Input() companyName:string ="";
  private confirmDeleteResult = null;
  constructor(private simpleModalService: SimpleModalService,
              private deleteEmployeeService: DeleteEmployeeService,
              private sendEmailService: SendEmailService,
              private router: Router,
              private tokenExpirationService: TokenExpirationService) { }

  ngOnInit() {
  }

  private changeToSended() {
    let element: HTMLElement = document.getElementById("email-employee-" + this.employee.id);
    element.classList.remove('fa', 'fa-envelope', 'fa-envelope-open', 'fa-check', 'fa-time', 'spinner-border');
    element.classList.add('fa', 'fa-envelope-open');
  }

ngAfterViewInit(){
  if (this.employee.emailSent == true) {
    this.changeToSended();
  }
}
removeEmployee() {
  this.simpleModalService.addModal(DeleteEmployeeComponent, {
    title: 'Der Mitarbeiter wird mitsamt seinem Feedback aus dem System gelöscht. Sind Sie sicher?',
    message: 'Eintrag Löschen'
  }
  ).subscribe(isConfirmed => {
    this.confirmDeleteResult = isConfirmed;
    if (this.confirmDeleteResult === true) {

      this.deleteEmployeeService.deleteEmployee(this.employee.id, this.companyId).subscribe(response => {
        this.employeeDeleted.emit(this.employee.id);
        //show message with succes delete company
      }, error => {
        this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
      });
    }
    this.confirmDeleteResult = false;
  }, error => {
    this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
  })
}

sendEmail(){
  this.changeToSpinner();
  this.sendEmailService.sendEmailToEmployee(this.companyId, this.employee.id).subscribe(response => {
    setTimeout(() => {
      this.changeSpinnerToCheck();
    }, 2000);
    setTimeout(() => {
      this.changeToSended();
    }, 10000)
  }, error => {
    this.changeToSpinnerToError();
    this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
  });
}
showFeedback(){
  this.router.navigate(['/employee',this.companyName, this.employee.email,  this.employee.id]);
}
  private changeSpinnerToCheck(){
  let element: HTMLElement = document.getElementById("email-employee-" + this.employee.id);
  element.classList.remove('spinner-border');
  element.classList.add('fa', 'fa-check');
}
  private changeToSpinner(){
  let element: HTMLElement = document.getElementById("email-employee-" + this.employee.id);
  element.classList.remove('fa', 'fa-envelope', 'fa-envelope-open', 'fa-check', 'fa-time');
  element.classList.add('spinner-border');
}
  private changeToSpinnerToError(){
  let element: HTMLElement = document.getElementById("email-employee-" + this.employee.id);
  element.classList.remove('spinner-border');
  element.classList.add('fa', 'fa-times');
}

}
