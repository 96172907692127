import { Observable } from 'rxjs';
import { AddEmployeeDTO } from './../../../../modules/employees/dto/add-employee-dto';
import { AddEmployeeRepository } from './../../../repositories/employees/add-employee/add-employee-repository';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddEmployeeService {

  constructor(private employeeRepository:AddEmployeeRepository) { }

  addEmployee(companyId:number, employee: AddEmployeeDTO):Observable<any>{
    return this.employeeRepository.addEmployeetoCompany(companyId, employee);
  }
}
