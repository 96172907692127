import { TokenExpirationService } from './../../../core/services/auth-service/token-expiration/token-expiration.service';
import { CompanyDTO } from 'src/app/modules/companies/dto/company-dto';
import { CompanyDtoToCompanyMapper } from './../mapper/company-dto-to-company-mapper';
import { AddCompanyService } from './../../../core/services/companies/add/add-company.service';
import { AddCompany } from './../domain/add-company';
import { Company } from './../domain/company';
import { ValidationMessageService } from 'src/app/shared/services/validation-message-service/validation-message.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Message } from 'src/app/core/message-section/message';

@Component({
  selector: 'add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {
  private redError: string = "#e26868";
  private greenSucces: string = "#71bc4b";
  private readonly secondsToResetSuccessMessage: number = 5;

  @Input() companies: Array<Company>;
  @Output() companyAdded = new EventEmitter<boolean>();

  textMessage: string;
  private addCompanyForm: FormGroup;
  private validationMessages: any;
  private message: Message;
  activateForm: boolean;
  constructor(private formBuilder: FormBuilder, private validationMessageService: ValidationMessageService, private addCompanyService: AddCompanyService, private tokenExpirationService: TokenExpirationService) { }

  ngOnInit() {
    this.addCompanyForm = this.createFormGroup();
    this.activateForm = false;
    this.textMessage = '';
    this.validationMessages = this.createValidationMessages();
  }
  showForm() {
    this.activateForm = !this.activateForm;
    if (this.activateForm === false) {
      this.textMessage = '';
      this.addCompanyForm.reset();
    }
  }
  saveCompany() {
    if (this.addCompanyForm.invalid) {
      this.textMessage = this.mapErrorMessages()[0];
      this.changeColorMessage(this.redError);
    }
    else {
      let newCompany: AddCompany = new AddCompany(this.addCompanyForm.value.companyName.trim());
      this.addCompanyService.addCompany(newCompany).
        subscribe(response => {
          let companyDTO: CompanyDTO = response;
          let company: Company = CompanyDtoToCompanyMapper.map(companyDTO);
          this.companies.push(company);
          this.companyAdded.emit(true);
          this.textMessage = 'Firma wurde erfolgreich erstellt';
          this.changeColorMessage(this.greenSucces);
          this.resetMessage();
        }, error => {
          let response: Response = error;
          this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
          if (response.status === 409) {
            this.textMessage = "Der Name wird bereits verwendet";
          } else {
            this.textMessage = "Unerwarteter Fehler";
          }
          this.addCompanyForm.reset();
          this.changeColorMessage(this.redError);
        });
      this.addCompanyForm.reset();
    }

  }

  private resetMessage(): void {
    setTimeout(() => {
      this.textMessage = "";
    }, this.secondsToResetSuccessMessage * 1000);
  }

  private createValidationMessages(): any {
    return (
      {
        companyName: {
          required: "Der Firmenname ist erforderlich",
          maxlength: "Der Firmenname ist zu lang"
        }
      });
  }
  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      companyName: ["", [Validators.required, Validators.maxLength(50)]]
    })
  }
  private mapErrorMessages(): Array<string> {
    return this.validationMessageService.mapErrorMessages(this.addCompanyForm, this.validationMessages);
  }

  private changeColorMessage(color: string) {
    let messagetext: HTMLElement = document.getElementById("text-company-message");
    messagetext.style.color = color;

  }
}
