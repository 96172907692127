export class Company {
  companyName: string;
  feedbackStatus: number;
  id: number;
  constructor(id: number, companyName: string, feedbackStatus: number) {
    this.id = id;
    this.companyName = companyName;
    this.feedbackStatus = feedbackStatus;
  }
  getCompanyName(): string {
    return this.companyName;
  }
  getFeedbackStatus(): number {
    return this.feedbackStatus;
  }
  getId(): number {
    return this.id;
  }
}
