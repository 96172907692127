import { MethodosModule } from './modules/methodos/methodos.module';
import { EmployeesModule } from './modules/employees/employees.module';
import { CompaniesModule } from './modules/companies/companies.module';

import { ContentTypeInterceptor } from './core/interceptors/content-type-interceptor';
import { JwtInterceptor } from './core/interceptors/jwt-interceptor';
import { LoginModule } from './modules/login/login.module';
import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PasswordModule } from './modules/password/password.module';
import { SurveyCircleModule } from './modules/survey-circle/survey-circle.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    EmployeesModule,
    CompaniesModule,
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    HttpClientModule,
    CoreModule,
    LoginModule,
    SharedModule,
    PasswordModule,
    ReactiveFormsModule,
    SurveyCircleModule,
    MethodosModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentTypeInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
