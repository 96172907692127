import { MessageTypeEnum } from './message-type-enum';
export class Message {
  private readonly type: MessageTypeEnum;
  private readonly messages: Array<string>;
  constructor(type: MessageTypeEnum, message: Array<string>) {
    this.type = type;
    this.messages = message;
  }

  getType():MessageTypeEnum{
    return this.type;
  }

  getMessages(): Array<string> {
    return this.messages;
  }
}
