import { ListMethodosComponent } from './modules/methodos/list-methodos/list-methodos.component';
import { IdealLineDisplayComponent } from './modules/survey-circle/ideal-line-display/ideal-line-display.component';
import { CompanyFeedbackComponent } from './modules/survey-circle/company-feedback/company-feedback.component';
import { FeedbackDisplayComponent } from './modules/survey-circle/feedback-display/feedback-display.component';
import { RequestPasswordComponent } from './modules/password/pages/request-password/request-password.component';
import { AuthGuard } from './core/guards/auth-guard/auth.guard';
import { PublicLayoutComponent } from './core/layout/public-layout/public-layout.component';
import { SecureLayoutComponent } from './core/layout/secure-layout/secure-layout.component';
import { DatenschutzComponent } from './core/pages/datenschutz/datenschutz.component';
import { ImpressumComponent } from './core/pages/impressum/impressum.component';
import { LoginComponent } from './modules/login/pages/login/login.component';
import { DashboardComponent } from './modules/dashboard/pages/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UpdatePasswordComponent } from './modules/password/pages/update-password/update-password.component';
import { SurveyLayoutComponent } from './core/layout/survey-layout/survey-layout.component';
import { EmployeeFeedbackComponent } from './modules/survey-circle/employee-feedback/employee-feedback.component';
import { ShowMethodoInfoComponent } from './modules/methodos/show-methodo-info/show-methodo-info.component';

const routes: Routes = [
  {
    path: '',
    component: SecureLayoutComponent,
    children: [
      {
        path: '', component: DashboardComponent
      },
      {
        path: 'company/:companyName/:companyId', component: CompanyFeedbackComponent
      },
      {
        path: 'employee/:company/:employee/:employeeId', component: EmployeeFeedbackComponent
      }
      ,
      {
        path: 'ideal-line/:companyName/:companyId', component: IdealLineDisplayComponent
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: SurveyLayoutComponent,
    children: [
      {
        path: 'survey', component: FeedbackDisplayComponent
      }
    ]
  },
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: 'impressum', component: ImpressumComponent
      },
      {
        path: 'datenschutz', component: DatenschutzComponent
      },
      {
        path: 'request-password', component: RequestPasswordComponent
      },
      {
        path: 'password-update', component: UpdatePasswordComponent
      },
      {
        path: 'methode-info/:methodeId', component: ShowMethodoInfoComponent
      },
      {
        path: 'methodes', component: ListMethodosComponent
      },

    ]
  },
  {
    path: 'login/:logoutMessage', component: LoginComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
