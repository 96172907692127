import { AddEmployeeDTO } from './../dto/add-employee-dto';
import { AddEmployee } from './../model/add-employee';
export class AddEmployeeToAddEmployeeDTOMapper{
  public static map(addEmployee:AddEmployee):AddEmployeeDTO{
    let addEmployeeDTO:AddEmployeeDTO = new AddEmployeeDTO();
    addEmployeeDTO.email = addEmployee.email;
    addEmployeeDTO.department = addEmployee.department;
    addEmployeeDTO.responsability = addEmployee.responsability;
    addEmployeeDTO.workexperience = addEmployee.workexperience;
    return addEmployeeDTO;
  }
}
