import { SurveyDisplay } from '../survey-enum';
import { GroupSurveyCircleModel } from '../../../../modules/survey-circle/model/group-survey-circle-model';
import { Injectable } from '@angular/core';
import { RangeDegrees } from 'src/app/modules/survey-circle/model/range-degrees';

@Injectable({
  providedIn: 'root'
})
export class FetchSurveyCircleGroupsService {
  private static surveysCircleIDs: Array<GroupSurveyCircleModel> =
    //LEFT GROUP SECTION
    [new GroupSurveyCircleModel(1, 'Ressourcen_2_', 'Ressourcen', 'Line-Ressourcen', 'Curve-Ressourcen', SurveyDisplay.LEFT, new RangeDegrees(251, 269), 1),
    new GroupSurveyCircleModel(2, 'Führung_und_Unternehmenskultur', 'Führung und Unternehmenskultur', 'Line-Führung_und_Unternehmenskultur', 'Curve-Führung_und_Unternehmenskultur', SurveyDisplay.LEFT, new RangeDegrees(231, 249), 2),
    new GroupSurveyCircleModel(3, 'Innovationsstrategie', 'Innovationsstrategie', 'Line-Innovationsstrategie', 'Curve-Innovationsstrategie', SurveyDisplay.LEFT, new RangeDegrees(211, 229), 3),
    new GroupSurveyCircleModel(4, 'Prozessmanagement_1_', 'Prozessmanagement', 'Line-Prozessmanagement', 'Curve-Prozessmanagement', SurveyDisplay.LEFT, new RangeDegrees(191, 209), 4),
    new GroupSurveyCircleModel(5, 'Organisationsstruktur', 'Organisationsstrukturen', 'Line-Organisationsstrukturen', 'Curve-Organisationsstrukturen', SurveyDisplay.LEFT, new RangeDegrees(171, 189), 5),
    new GroupSurveyCircleModel(6, 'Forschung_und_Entwicklung_1_', 'Forschung und Entwicklung', 'Line-Forschung_und_Entwicklung', 'Curve-Forschung_und_Entwicklung', SurveyDisplay.LEFT, new RangeDegrees(151, 169), 6),
    new GroupSurveyCircleModel(7, 'Ideenmanagement_1_', 'Ideenmanagement', 'Line-Ideenmanagement', 'Curve-Ideenmanagement', SurveyDisplay.LEFT, new RangeDegrees(131, 149), 7),
    new GroupSurveyCircleModel(8, 'Wissensmanagment', 'Wissensmanagement', 'Line-Wissensmanagement', 'Curve-Wissensmanagement', SurveyDisplay.LEFT, new RangeDegrees(111, 129), 8),
    new GroupSurveyCircleModel(9, 'Qualifikation_und_Kompetenzen', 'Qualifikation und Kompetenzen', 'Line-Qualifikation_und_Kompetenzen', 'Curve-Qualifikation_und_Kompetenzen', SurveyDisplay.LEFT, new RangeDegrees(91, 109), 9),
    //RIGHT GROUP SECTION
    new GroupSurveyCircleModel(10, 'Status_der_Produkte_und_Dienstleistungen', 'Status der Produkte und Dienstleistungen', 'Line-Status_der_Produktion', 'Curve-Status_der_Produkte_und_Dienstleistungen', SurveyDisplay.RIGHT, new RangeDegrees(271, 299), 19),
    new GroupSurveyCircleModel(11, 'Innovationspotentiale_1_', 'Innovationspotentiale', 'Line-Innovationspotentiale', 'Curve-Innovationspotentiale', SurveyDisplay.RIGHT, new RangeDegrees(301, 329), 18),
    new GroupSurveyCircleModel(12, 'Trend-_und_Technologieorientierung', 'Trend- und Technologie- orientierung', 'Line-Trend-_und_Technologieorientierung', 'Curve-Trend-_und_Technologieorientierung', SurveyDisplay.RIGHT, new RangeDegrees(331, 339), 17),
    new GroupSurveyCircleModel(13, 'Kundenorientierung_1_', 'Kundenorientierung', 'Line-Kundenorientierung', 'Curve-Kundenorientierung', SurveyDisplay.RIGHT, new RangeDegrees(341, 349), 16),
    new GroupSurveyCircleModel(14, 'Nutzeorientierung', 'Nutzerorientierung', 'Line-Nutzerorientierung', 'Curve-Nutzeorientierung', SurveyDisplay.RIGHT, new RangeDegrees(351, 359), 15),
    new GroupSurveyCircleModel(15, 'Lieferanteneinbindung_1_', 'Lieferanteneinbindung', 'Line-Lieferanteneinbindung', 'Curve-Lieferanteneinbindung', SurveyDisplay.RIGHT, new RangeDegrees(1, 9), 14),
    new GroupSurveyCircleModel(16, 'Wettbewerbsberücksichtigung', 'Wettbewerbsberück- sichtigung', 'Line-Wettbewerbsberücksichtigung', 'Curve-Wettbewerbsberücksichtigung', SurveyDisplay.RIGHT, new RangeDegrees(11, 19), 13),
    new GroupSurveyCircleModel(17, 'Vermarktung', 'Vermarktung', 'Line-Vermarktung', 'Curve-Vermarktung', SurveyDisplay.RIGHT, new RangeDegrees(21, 29), 12),
    new GroupSurveyCircleModel(18, 'Koopertaion_mit_wissenschaftlichen_Einrichtungen', 'Kooperation mit wissenschaftlichen Einrichtungen', 'Line-Kooperation_mit_wissenschaftlichen_Einrichtungen', 'Curve-Koopertaion_mit_wissenschaftlichen_Einrichtungen', SurveyDisplay.RIGHT, new RangeDegrees(31, 59), 11),
    new GroupSurveyCircleModel(19, 'Kooperation_mit_Unternehmen_und_Konsortien_1_', 'Kooperation mit Unternehmen und Konsortien', 'Line-Kooperationen_mit_Unternehmen_und_Konsortien', 'Curve-Kooperation_mit_Unternehmen_und_Konsortien', SurveyDisplay.RIGHT, new RangeDegrees(61, 89), 10)];
  constructor() { }

  public static getAllGroupsSurveysCircles(): Array<GroupSurveyCircleModel> {
    return FetchSurveyCircleGroupsService.surveysCircleIDs;
  }

  public static hideAllLinesExceptFromGroupById(groupId: number) {

    FetchSurveyCircleGroupsService.surveysCircleIDs.forEach(group => {
      if (group.id === groupId) {
        group.showLines(true);
      } else {
        group.showLines(false);
      }
      window.focus();
    });
  }
  public static hideALl() {
    FetchSurveyCircleGroupsService.surveysCircleIDs.forEach(group => {
      group.showLines(false);
    });
  }
  public static getDegreeFromGroupId(groupId: number, position: number): number {

    let degree = null;
    this.surveysCircleIDs.forEach(group => {
      if (group.id === groupId) {
        degree = group.rangeDegree.initRange + (Math.abs(group.rangeDegree.finalRange - group.rangeDegree.initRange) * position);
      }
    })
    if (!degree) {
      throw new Error("groupId not Found");

    }
    return degree;
  }
  public static getPositionFromGroupId(groupId: number): number {
    let position = null;
    this.surveysCircleIDs.forEach(group => {
      if (group.id === groupId) {
        position = group.position;
      }
    })
    if (!position) {
      throw new Error("groupId not Found");

    }
    return position;
  }
  public static getGroupNameByGroupID(groupId: number): string {
    let groupName = "Invalid Group";
    this.surveysCircleIDs.forEach(group => {
      if (group.id === groupId) {
        groupName = group.groupName;
      }
    })
    return groupName;
  }
  public static getDisplayByGroupId(groupId: number): SurveyDisplay {
    let display: SurveyDisplay;
    this.surveysCircleIDs.forEach(group => {
      if (group.id === groupId) {
        display = group.display;
      }
    });
    if (display===null) {
      throw new Error("groupId not Found");
    }
    return display;
  }

}
