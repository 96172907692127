import { IdealPointDto } from './../../../../modules/survey-circle/ideal-line-display/dto/ideal-point-dto';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AddIdealPointRepository {

  constructor(private http: HttpClient) {
  }

  addIdealPointToCompany(companyId:number, idealPointDto:IdealPointDto): Observable<any> {
    const url: string = environment.rootUrl + environment.endpoints.companies + "/"+ companyId + "/" + environment.endpoints.idealAverage;
    return this.http.put(url, JSON.stringify(idealPointDto));
  }

}
