import { SimpleModalModule } from 'ngx-simple-modal';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayEmployeesComponent } from './display-employees/display-employees.component';
import { DisplayEmployeeComponent } from './display-employees/display-employee/display-employee.component';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { DeleteEmployeeComponent } from './delete-employee/delete-employee.component';

@NgModule({
  declarations: [DisplayEmployeesComponent, DisplayEmployeeComponent, AddEmployeeComponent, DeleteEmployeeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SimpleModalModule.forRoot({ container: "modal-container" })
  ],
  exports:[
    DisplayEmployeesComponent,
    DisplayEmployeeComponent
  ], entryComponents: [
    DeleteEmployeeComponent
  ]
})
export class EmployeesModule { }
