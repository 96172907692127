import { SurveyQuestionPopUpComponent } from './survey-question-pop-up/survey-question-pop-up.component';
import { FetchSurveyCircleGroupsService } from 'src/app/core/services/survey/fetch-survey-circle-groups/fetch-survey-circle-groups.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SurveyResponse } from '../model/survey-response';
import { SimpleModalService } from 'ngx-simple-modal';
import { SurveyDisplay } from 'src/app/core/services/survey/survey-enum';

@Component({
  selector: 'survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.scss']
})
export class SurveyQuestionComponent implements OnInit {
  @Input() actualQuestions: Array<SurveyResponse> = new Array();
  @Input() groupName: string = "";
  @Input() display: SurveyDisplay;
  @Input() clickable: boolean = true;
  @Output() updatedQuestion = new EventEmitter<SurveyResponse>();
  @Output() savedQuestion = new EventEmitter<SurveyResponse>();
  @Output() closeQuestion = new EventEmitter<SurveyDisplay>();
  constructor(private simpleModalService: SimpleModalService) {

  }
  ngOnInit() {
  }
  private showQuestion(survey: SurveyResponse) {
    if (this.clickable === true) {
      this.simpleModalService.addModal(SurveyQuestionPopUpComponent, {
        title: survey.question,
        message: "" + survey.response
      }).subscribe(response => {
        let surveyResponse: SurveyResponse = new SurveyResponse(survey.questionId, survey.question, survey.questionGroup, response);
        if (response !== null) {
          if (survey.response !== null) {
            surveyResponse.response = response;
            this.updatedQuestion.emit(surveyResponse);
          } else {
            surveyResponse.response = response;
            this.savedQuestion.emit(surveyResponse);
          }
        }
      });
    }

  }
   closeSurveyQuestion() {
    this.closeQuestion.emit(this.display);
  }
}
