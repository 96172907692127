import { TokenExpirationService } from './../../../core/services/auth-service/token-expiration/token-expiration.service';
import { FetchSurveyIdealAverageDto } from './../dto/fetch-survey-ideal-average-dto';
import { FetchCompanyResponsesService } from './../../../core/services/survey/fetch-company-responses/fetch-company-responses.service';
import { MessageTypeEnum } from './../../../core/message-section/message-type-enum';
import { FetchSurveyQuestionsService } from './../../../core/services/survey/fetch-survey-questions/fetch-survey-questions.service';
import { FetchColorsService } from './../../../core/services/survey/color-service/fetch-colors.service';
import { FetchCloudPointDto } from './../dto/fetch-cloud-point-dto';
import { SurveyQuestion } from './../model/survey-question';
import { Message } from 'src/app/core/message-section/message';
import { GroupSurveyCircleModel } from './../model/group-survey-circle-model';
import { FetchSurveyCircleGroupsService } from './../../../core/services/survey/fetch-survey-circle-groups/fetch-survey-circle-groups.service';
import { GroupAverage } from './../model/group-average';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { PaintGroupAveragesLine } from '../model/paint-group-averages-line';
import { SurveyDisplay } from 'src/app/core/services/survey/survey-enum';
import { SurveyResponse } from '../model/survey-response';
import { PaintCloudPoint } from '../model/paint-cloud-point';

@Component({
  selector: 'company-feedback',
  templateUrl: './company-feedback.component.html',
  styleUrls: ['./company-feedback.component.scss']
})
export class CompanyFeedbackComponent implements OnInit {
  private readonly colorIdealLine: string = "#1b7d29";
  private readonly styleIdealLine: string = "10,10";
  private readonly colorCompanyLine: string = "red";
  private readonly styleCompanyLine: string = "(1)";

  companyId: number;
  companyName: string = "";
  readonly left: SurveyDisplay = SurveyDisplay.LEFT;
  readonly right: SurveyDisplay = SurveyDisplay.RIGHT;
  allQuestions: Array<SurveyResponse> = new Array();
  message: Message;
  //PopUp Varibles
  showLeft: boolean = false;
  showRight: boolean = false;
  actualGroupId: number = 0;
  actualGroupName: string = "Survey Response Group Id";
  actualQuestions: Array<SurveyResponse> = new Array();
  showMethodos: boolean = false;

  //SVG Variabels
  cloudPoints: Array<PaintCloudPoint> = new Array();
  responsesGroupAverages: Array<PaintGroupAveragesLine> = new Array();

  constructor(private route: ActivatedRoute, private companyResponseService: FetchCompanyResponsesService, private fetchQuestionsService: FetchSurveyQuestionsService, private tokenExpirationService: TokenExpirationService) { }

  ngOnInit() {
    this.takeCompanyIdAndNameFromParams();
    this.getAllQuestions();
    this.loadCompanyFeedback();
    this.loadEmployeeCloudPoints();
  }
  private takeCompanyIdAndNameFromParams() {
    this.route.params.subscribe(params => {
      this.companyId = params['companyId'];
      this.companyName = params['companyName'];
    });
  }
  private getAllQuestions() {
    this.fetchQuestionsService.getSurveyQuestions().subscribe(response => {
      let questions: Array<SurveyQuestion> = response;
      questions.forEach(surveyQuestion => {
        this.allQuestions.push(new SurveyResponse(surveyQuestion.surveyQuestionId, surveyQuestion.question, surveyQuestion.surveyQuestionGroupId, null));
      });
    }, error => {
      this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
      this.message = new Message(MessageTypeEnum.ERROR, ["Ein unerwarteter Fehler ist aufgetreten."]);
    });
  }
  private loadCompanyFeedback() {
    this.responsesGroupAverages.splice(0, this.responsesGroupAverages.length);
    this.companyResponseService.getIdealAverageFromCompany(this.companyId).subscribe(idealAverage => {
      this.responsesGroupAverages.push(this.getPaintGroupAverageFromResponse(idealAverage, this.colorIdealLine, this.styleIdealLine));
      this.companyResponseService.getTotalAverageFromCompany(this.companyId).subscribe(companyAverage => {
        this.responsesGroupAverages.push(this.getPaintGroupAverageFromResponse(companyAverage, this.colorCompanyLine, this.styleCompanyLine));
      },
        error => {
          this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
          this.message = new Message(MessageTypeEnum.ERROR, ["Ein unerwarteter Fehler ist aufgetreten."]);
        });
    }, error => {
      this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
      this.message = new Message(MessageTypeEnum.ERROR, ["Ein unerwarteter Fehler ist aufgetreten."]);
    });
  }
  private getPaintGroupAverageFromResponse(response: any, colorLine: string, styleLine: string): PaintGroupAveragesLine {
    let averages: Array<FetchSurveyIdealAverageDto> = response;
    let groupAverages: Array<GroupAverage> = new Array();
    averages.filter(average => { return average.average !== null }).forEach(idealAverage => {
      groupAverages.push(new GroupAverage(idealAverage.surveyQuestionGroupId, idealAverage.average, FetchSurveyCircleGroupsService.getDegreeFromGroupId(idealAverage.surveyQuestionGroupId, 0.5)))
    });
    return new PaintGroupAveragesLine(groupAverages, colorLine, styleLine);
  }
  private loadEmployeeCloudPoints() {

    this.companyResponseService.getAllResponsesFromCompany(this.companyId).subscribe(response => {
      let cloudPoints: Array<FetchCloudPointDto> = this.getCloudPointsOrderByGroupFromResponse(response);
      this.cloudPoints = new Array();
      for (let i = 0; i < cloudPoints.length;) {
        let count: number = this.getCountResponseWithTheSameGroupId(cloudPoints, i);
        for (let j = 1; j <= count; j++) {
          this.cloudPoints.push(
            new PaintCloudPoint(
              cloudPoints[i].average,
              FetchSurveyCircleGroupsService.getDegreeFromGroupId(cloudPoints[i].surveyQuestionGroupId, this.getPositionFromPoint(count, j)),
              this.getColorFromEmployeeId(cloudPoints[i].employeeId)));
          i++;
        }
      }
    }, error => {
      this.tokenExpirationService.checkIfTokenIsExpiredAndLogOut(error.error.code, error.error.message);
      this.message = new Message(MessageTypeEnum.ERROR, ["Ein unerwarteter Fehler ist aufgetreten."]);
    })
  }
  private getCloudPointsOrderByGroupFromResponse(response: any): Array<FetchCloudPointDto> {
    let cloudPoints: Array<FetchCloudPointDto> = response;
    return cloudPoints.filter(cloudPoint => { return cloudPoint.average !== null }).sort((cloudPointBefore, cloudPointAfter) => { return cloudPointBefore.surveyQuestionGroupId - cloudPointAfter.surveyQuestionGroupId });
  }
  private getPositionFromPoint(size: number, position: number): number {
    return (1 / (size)) * (position - 1) + ((1 / size) / 2);
  }
  private getCountResponseWithTheSameGroupId(cloud: FetchCloudPointDto[], i: number) {
    return cloud.filter(c => { return c.surveyQuestionGroupId === cloud[i].surveyQuestionGroupId; }).length;
  }
  private getColorFromEmployeeId(id: number): string {
    let colors: Array<string> = FetchColorsService.getColors();
    return colors[id % colors.length];
  }
  onGroupClicked(group: GroupSurveyCircleModel) {
    this.showMethodos = false;
    FetchSurveyCircleGroupsService.hideAllLinesExceptFromGroupById(group.id);
    this.actualGroupName = group.groupName;
    this.actualQuestions = this.allQuestions.filter(question => { return question.questionGroup === group.id });
    this.checkShowDisplay(group.display);

  }
  onInformationClicked(idGroup: number) {
    this.showMethodos = true;
    this.actualGroupId = idGroup;
    this.actualGroupName = FetchSurveyCircleGroupsService.getGroupNameByGroupID(idGroup);
    FetchSurveyCircleGroupsService.hideAllLinesExceptFromGroupById(idGroup);
    this.checkShowDisplay(FetchSurveyCircleGroupsService.getDisplayByGroupId(idGroup));
  }
  private checkShowDisplay(display: SurveyDisplay) {
    if (display === SurveyDisplay.LEFT) {
      this.showInLeft();
    }
    else {
      this.showInRight();
    }
  }
  private showInLeft() {
    this.showLeft = true;
    this.showRight = false;
    window.scrollTo(0, 0);
  }
  private showInRight() {
    this.showLeft = false;
    this.showRight = true;
    window.scrollTo(1500, 0);
  }
  private closeQuestion(survey: SurveyDisplay) {
    if (survey === SurveyDisplay.LEFT) {
      this.showLeft = false;
    } else {
      this.showRight = false;
    }
    FetchSurveyCircleGroupsService.hideALl();
  }


}
