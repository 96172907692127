
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FetchSurveyRepository {
  constructor(private http: HttpClient) {

  }
  getSurveyFromEmployee(employeeToken: string): Observable<any> {
    const url: string = environment.rootUrl + environment.endpoints.survey + "/" + environment.endpoints.response;
    return this.http.get(url + '/' + employeeToken);
  }

  getEmployeeResponses(employeeId: number): Observable<any> {
    const url: string = environment.rootUrl
      + environment.endpoints.companies + "/"
      + environment.endpoints.employees + "/"
      + employeeId + "/" +
      environment.endpoints.surveyResponses
    return this.http.get(url);
  }

  getSurveyQuestions(): Observable<any> {
    const url: string = environment.rootUrl + environment.endpoints.surveyQuestion;
    return this.http.get(url);
  }
  getAllResponsesFromCompany(companyId: number): Observable<any> {
    const url: string = environment.rootUrl
      + environment.endpoints.companies
      + "/" + companyId + "/"
      + environment.endpoints.employees + "/"
      + environment.endpoints.average;
    return this.http.get(url);

  }
  getIdealAverageFromCompany(companyId:number):Observable<any>{
    const url: string = environment.rootUrl
      + environment.endpoints.companies
      +"/"+companyId
      +"/"+ environment.endpoints.idealAverage;
    return this.http.get(url);
  }
  getTotalAverageFromCompany(companyId: number):Observable<any>{
    const url: string = environment.rootUrl
      + environment.endpoints.companies
      + "/" + companyId + "/"
      + environment.endpoints.average;
    return this.http.get(url);
  }
}
