import { SurveyDisplay } from './../../../core/services/survey/survey-enum';
import { RangeDegrees } from './range-degrees';
export class GroupSurveyCircleModel {
  id: number;
  groupIdName: string;
  groupName: string;
  lineId: string;
  informationId: string;
  display: SurveyDisplay;
  rangeDegree: RangeDegrees;
  position: number;
  constructor(groupId: number, groupIdName: string, groupName: string, lineId: string, informationId: string, display: SurveyDisplay, rangeDegree: RangeDegrees, position: number) {
    this.groupIdName = groupIdName;
    this.groupName = groupName;
    this.id = groupId;
    this.lineId = lineId;
    this.informationId = informationId;
    this.display = display;
    this.rangeDegree = rangeDegree;
    this.position = position;
  }

  showLines(isShow: boolean) {

    let element: HTMLElement = document.getElementById(this.lineId);
    if (isShow === true) {
      element.style.visibility = "visible";
    } else {
      element.style.visibility = "hidden";
    }



  }


}
