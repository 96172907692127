export const environment = {
  production: true,
  envName: "Testing",
  contactform: "https://innolink.4a-side.zone/kontakt/",
  rootUrl: "https://innocheck-be.4a-side.zone/web/",
  mediaPathURl: "https://methodos.ik.ing.tu-bs.de/media/",
  endpoints: {
    login: "login",
    passwordRequest: "password-reset",
    passwordUpdate: "password-update",
    companies: "api/users/companies",
    employees: "employees",
    feedbackstatus: "feedbackstatus",
    invitationEmail: "invitationemail/send",
    survey: "surveys",
    response: "responses",
    surveyResponses: "surveyresponses",
    surveyQuestion: "api/surveyquestions",
    average: "average",
    idealAverage: "idealaverages",
    surveyquestiongroup: "surveyquestiongroup",
    methodos: "methodos",
    summary: "summary",
    mainInfo: "maininfo",
    strengthsAndWeaknesses: "strengthsweaknesses",
    implementations: "implementations",
    praxisExamples: "praxisexamples",
    links:"links",
    developments: "developments",
    assignments: "assignments",
    comments: "comments",
    literatures: "literatures",
    keywords: "keywords",
    inputs: "inputs",
    outputs: "outputs"
  }
};
