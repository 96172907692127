import { FetchMethodoInfoService } from './../../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { FetchStrengthsAndWeaknessesDto } from './../../dto/fetch-strengths-and-weaknesses-dto';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'strengths-weaknesses',
  templateUrl: './strengths-weaknesses.component.html',
  styleUrls: ['./strengths-weaknesses.component.scss']
})
export class StrengthsWeaknessesComponent implements OnInit {

  constructor(private fetchMethodoInfoService:FetchMethodoInfoService) { }
  @Input() methodeId:number=0;
  strengthsAndWeaknesses:FetchStrengthsAndWeaknessesDto = new FetchStrengthsAndWeaknessesDto();
  ngOnInit() {
    this.fetchMethodoInfoService.getMehotodoStrengthsAndWeaknesses(this.methodeId).subscribe(
      response=> {
        this.strengthsAndWeaknesses = response;
      }
    )
  }

}
