export class SaveResponseDto{
  token:string;
  questionId: number;
  response: number;
  constructor(token:string, questionId:number, response:number){
    this.token = token;
    this.questionId = questionId;
    this.response = response;
  }
}
