export class GroupAverage{
  groupId: number;
  responseAverage: number;
  degree: number;
  constructor(groupId:number, responseAverage:number, degree:number){
    this.responseAverage = responseAverage;
    this.groupId = groupId;
    this.degree = degree;
  }

}
