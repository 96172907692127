import { environment } from 'src/environments/environment';
import { LoginRepository } from '../../repositories/login-repository';
import { Injectable } from '@angular/core';
import { LoginUser } from 'src/app/modules/login/models/login-user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  readonly tokenKey: string = 'jwt_token';

  constructor(private loginRepository:LoginRepository) { }

  public login(user: LoginUser) {
    let url: string = environment.rootUrl + environment.endpoints.login;
    return this.loginRepository.login(url, user).pipe(map(
      authResponse => {
        if (authResponse.token) {
          localStorage.setItem(this.tokenKey, authResponse.token);
        }
      })
    );
  }

  public getToken(): string{
    return localStorage.getItem(this.tokenKey);
  }

  public logout(){
    localStorage.removeItem(this.tokenKey);
  }

  public isLoggedIn(): boolean {
    return localStorage.getItem(this.tokenKey) != null;
  }
}
