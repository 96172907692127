import { Observable } from 'rxjs';
import { AddCompany } from './../../../../modules/companies/domain/add-company';
import { AddCompanyToAddCompanyDTOMapper } from './../../../../modules/companies/mapper/add-company-dto-to-add-company-mapper';
import { AddCompanyRepository } from './../../../repositories/companies/add/add-company-repository';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AddCompanyService {

  constructor(private companyRepository: AddCompanyRepository) { }

  public addCompany(addCompany: AddCompany): Observable<any> {
    return this.companyRepository.addNewCompany(AddCompanyToAddCompanyDTOMapper.map(addCompany));
  }
}
