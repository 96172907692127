import { UpdatePasswordDTO } from './../dto/update-password-dto';
import { UpdatePassword } from './../model/update-password';
export class UpdatePasswordMapper{
  public static mapToDTO(domain: UpdatePassword): UpdatePasswordDTO {
    let dto = new UpdatePasswordDTO();
    dto.token = domain.token;
    dto.password = domain.password;
    return dto;
  }
}
