import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DeleteEmployeeReposiroty{
  constructor(private http: HttpClient){

  }
  deleteEmployee(employeeId:number, companyId:number):Observable<any>{
    const url: string = environment.rootUrl + environment.endpoints.companies +"/"+companyId+"/"+environment.endpoints.employees+"/"+employeeId;
    return this.http.delete(url);
  }
}
