import { FetchMethodeOutputDTO } from './../../dto/fetch-methode-output-dto';
import { FetchMethodoInfoService } from './../../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'methode-output-info',
  templateUrl: './methode-output-info.component.html',
  styleUrls: ['./methode-output-info.component.scss']
})
export class MethodeOutputInfoComponent implements OnInit {
  @Input() methodeId: number;
  public outputs: Array<FetchMethodeOutputDTO> = new Array();
  constructor(private fetchMethodoInfoService: FetchMethodoInfoService) { }

  ngOnInit() {
    this.fetchMethodoInfoService.getMethodeOutputInfo(this.methodeId).subscribe(response => {
      this.outputs = response;
    });
  }

}
