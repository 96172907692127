import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PasswordRequestRepository{

  constructor(private httpClient: HttpClient){
  }

  requestPasswordChange(url: string, sendLinkTo: string): Observable<any>{
    return this.httpClient.put(url, {email:sendLinkTo});
  }
}
