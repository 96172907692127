import { FetchMethodoInfoService } from './../../../../core/services/methodos/fetch-methodo-info/fetch-methodo-info.service';
import { FetchMethodePraxiExampleDto } from './../../dto/fetch-methode-praxi-example-dto';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'methode-praxi-examples-info',
  templateUrl: './methode-praxi-examples-info.component.html',
  styleUrls: ['./methode-praxi-examples-info.component.scss']
})
export class MethodePraxiExamplesInfoComponent implements OnInit {

  constructor(private fetchMethodoInfoService: FetchMethodoInfoService) { }

  @Input() methodeId: number;
  methodePraxiExamples: Array<FetchMethodePraxiExampleDto> = new Array();

  ngOnInit() {

    this.fetchMethodoInfoService.getMethodoPraxiExamplesInfo(this.methodeId).subscribe(response => {
      this.methodePraxiExamples = response;
    })

  }


}
