import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationMessageService {

  constructor() { }

  public mapErrorMessages(formToValidate: FormGroup, validationMessages: any): Array<string>{
    let errorMessages: Array<string> = new Array();
    for(let key in formToValidate.value){
      let fieldForm = formToValidate.get(key);
      if(fieldForm.invalid){
        for (let errorKey in fieldForm.errors){
          errorMessages.push(validationMessages[key][errorKey]);
        }
      }
    }
    return errorMessages;
  }
}
