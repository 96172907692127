
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SendEmailRepository{
  constructor(private http: HttpClient){

  }
  sendEmailFromCompanyToEmployee(companyId:number, employeeId:number):Observable<any>{
    const url: string = environment.rootUrl + environment.endpoints.companies +"/"+companyId+"/"+environment.endpoints.employees+"/"+employeeId+"/"+environment.endpoints.invitationEmail;
    return this.http.patch(url, {});
  }
}
