import { GetInfoElementsIdService } from './../../../core/services/survey/get-info-ids/get-info-elements-id.service';
import { PaintCloudPoint } from './../model/paint-cloud-point';
import { PointFromGroup } from './../model/point-from-group';
import { CalculatePointService } from './../../../core/services/survey/calculate-point/calculate-point.service';
import { Point } from './../../../core/services/survey/calculate-point/point';
import { GroupAverage } from './../model/group-average';
import { FetchSurveyCircleGroupsService } from 'src/app/core/services/survey/fetch-survey-circle-groups/fetch-survey-circle-groups.service';
import { GroupSurveyCircleModel } from './../model/group-survey-circle-model';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, ElementRef, ViewChild, Renderer2, SimpleChanges, SimpleChange } from '@angular/core';
import { PaintGroupAveragesLine } from '../model/paint-group-averages-line';
import { elementEnd, element } from '@angular/core/src/render3';


@Component({
  selector: 'survey-circle-svg',
  templateUrl: './survey-circle-svg.component.html'
})
export class SurveyCircleSvgComponent implements OnInit {

  private elements: Array<any> = new Array();
  private readonly numOfGroups: number = 19;
  private readonly radioPoint: string = '5';
  private readonly strokeWidth: string = '2';
  private readonly radioCloudPoints: string = "3";
  surveysCircleIDs: any;
  @Input() isQuestionClickable: boolean = true;
  @Input() isInformationClickable: boolean = true;
  @Input() isChanged: any;
  @Input() cloudPoints: Array<PaintCloudPoint> = new Array();
  @Input() responsesGroupAverages: Array<PaintGroupAveragesLine> = new Array();

  @Output() isInformationClicked = new EventEmitter<number>();
  @Output() isGroupClicked = new EventEmitter<GroupSurveyCircleModel>();

  @ViewChild('svg') svg: ElementRef;

  constructor(private calculatePointService: CalculatePointService, private renderer: Renderer2) {
  }
  ngOnInit() {
    this.hideAllLines();
    this.makeSvgClickable();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.deleteAllPoints();
    this.paintCloudPoint();
  }
  ngDoCheck() {
    this.responsesGroupAverages.forEach(averages => {
      this.paintAllLinesSvg(averages);
    });
  }
  private paintAllLinesSvg(averages: PaintGroupAveragesLine) {
    let points: Array<PointFromGroup> = this.calculatePoints(averages.groupAverages);
    this.paintPointsInSVG(points, averages.color);
    this.paintLinesInSVG(points, averages.color, averages.lineStyle);

  }
  private paintCloudPoint() {
    if (this.cloudPoints.length > 0) {
      this.cloudPoints.forEach(pointToPaint => {
        let p: Point = this.calculatePointService.getPoint(pointToPaint.score, pointToPaint.degree);
        let point: any = this.getPointElement(p, pointToPaint.color, this.radioCloudPoints);
        this.renderer.appendChild(this.svg.nativeElement, point);
        this.elements.push(point);
      })
    }
  }
  private calculatePoints(averages: Array<GroupAverage>): Array<PointFromGroup> {
    let points: Array<PointFromGroup> = new Array();
    averages.forEach(groupAverage => {
      points.push(
        new PointFromGroup(FetchSurveyCircleGroupsService.getPositionFromGroupId(groupAverage.groupId),
          this.calculatePointService.getPoint(groupAverage.responseAverage, groupAverage.degree)));
    });
    return points;
  }
  private paintPointsInSVG(linesPoint: Array<PointFromGroup>, color: string) {
    linesPoint.forEach(response => {
      let point: any = this.getPointElement(response.point, color, this.radioPoint);
      this.renderer.appendChild(this.svg.nativeElement, point);
      this.elements.push(point);
    })
  }
  private getPointElement(response: Point, color: string, radioPoint: string): any {
    const g = this.renderer.createElement("g", 'svg');
    const title = this.renderer.createElement('title', 'svg');
    this.renderer.setProperty(title, 'innerHTML', response.title);
    this.renderer.appendChild(g, title);
    const circle = this.renderer.createElement("circle", 'svg');
    this.renderer.setAttribute(circle, "cx", "" + response.cx);
    this.renderer.setAttribute(circle, "cy", "" + response.cy);
    this.renderer.setAttribute(circle, "r", radioPoint);
    this.renderer.setAttribute(circle, "fill", color);
    this.renderer.appendChild(g, circle);
    return g;
  }
  private paintLinesInSVG(linesPoint: Array<PointFromGroup>, color: string, lineStyle: string) {
    let length = linesPoint.length
    linesPoint.sort((point1, point2) => { return point1.positionFromGroup - point2.positionFromGroup });
    for (let i = 0; i < linesPoint.length; i++) {
      if (this.arePointsCorrelative(linesPoint[i], linesPoint[(i + 1) % length])) {
        let line: any = this.getLineElement(linesPoint[i].point, linesPoint[(i + 1) % length].point, color, lineStyle)
        this.renderer.appendChild(this.svg.nativeElement, line);
        this.elements.push(line);
      }
    }
  }
  private getLineElement(point1: Point, point2: Point, color: string, lineStyle: string): any {
    const line = this.renderer.createElement("line", 'svg');
    this.renderer.setAttribute(line, "x1", "" + point1.cx);
    this.renderer.setAttribute(line, "x2", "" + point2.cx);
    this.renderer.setAttribute(line, "y1", "" + point1.cy);
    this.renderer.setAttribute(line, "y2", "" + point2.cy);
    this.renderer.setAttribute(line, "stroke", color);
    if (lineStyle) {
      this.renderer.setAttribute(line, "stroke-dasharray", lineStyle);
    }
    this.renderer.setAttribute(line, "stroke-width", this.strokeWidth);
    return line;
  }
  private deleteAllPoints() {
    this.elements.forEach(element => {
      if (this.renderer.parentNode(element) === this.svg.nativeElement) {
        this.renderer.removeChild(this.svg.nativeElement, element);
      }
    })
    this.elements.splice(0, this.elements.length);
  }
  private hideAllLines() {
    FetchSurveyCircleGroupsService.hideALl();
  }
  private makeSvgClickable() {
    FetchSurveyCircleGroupsService.getAllGroupsSurveysCircles().forEach(group => {
      if (this.isQuestionClickable === true) {
        this.makeQuestionClickable(group);
      }
      if (this.isInformationClickable === true) {
        this.makeInformationClickable(group);
      }
    });
  }
  private makeInformationClickable(group: GroupSurveyCircleModel) {
    this.setClickInformationEventToElement(document.getElementById(group.informationId), group.id);
    this.setClickInformationEventToElement(document.getElementById(GetInfoElementsIdService.getInfoIdByGroupId(group.id).infoCircleId), group.id);
    this.setClickInformationEventToElement(document.getElementById(GetInfoElementsIdService.getInfoIdByGroupId(group.id).infoIId), group.id);
    this.setClickInformationEventToElement(document.getElementById(GetInfoElementsIdService.getInfoIdByGroupId(group.id).infoSectionId), group.id);

  }
  private setClickInformationEventToElement(element: HTMLElement, groupId: number) {
    let _this = this;
    element.onclick = function () {
      _this.clickInformationEvent(groupId);
    }
    element.style.cursor = 'pointer';
  }
  private makeQuestionClickable(group: GroupSurveyCircleModel) {
    let element: HTMLElement = document.getElementById(group.groupIdName);
    let _this = this;
    element.onclick = function () {
      _this.clickGroupEvent(group);
    };
    element.style.cursor = 'pointer';
  }
  private arePointsCorrelative(point1: PointFromGroup, point2: PointFromGroup): boolean {
    return (((point1.positionFromGroup) % this.numOfGroups) + 1) === point2.positionFromGroup;
  }
  public clickGroupEvent(groupdClicked: GroupSurveyCircleModel) {
    this.isGroupClicked.emit(groupdClicked);
  }
  public clickInformationEvent(informationClicked: number) {
    this.isInformationClicked.emit(informationClicked);
  }
}
