import { Router } from '@angular/router';
import { AuthService } from './../../../../core/services/auth-service/auth.service';
import { Component, OnInit } from '@angular/core';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'header-user-info',
  templateUrl: './header-user-info.component.html',
  styleUrls: ['./header-user-info.component.scss']
})
export class HeaderUserInfoComponent implements OnInit {

  username: string;
  showLogOut:boolean = false;
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.username = this.getUsernameFromToken();
  }

  private getUsernameFromToken(): string {
    let decoded: any = jwt_decode(this.authService.getToken());
    return decoded.username;
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
