import { Component, OnInit, Input } from '@angular/core';
import { Message } from '../message';

@Component({
  selector: 'message-section',
  templateUrl: './message-section.component.html',
  styleUrls: ['./message-section.component.scss']
})
export class MessageSectionComponent implements OnInit {

  @Input('message')message: Message;

  constructor() { }

  ngOnInit() {
  }

  public reset() {
    this.message = null;
  }

}
