import { Workexperience } from './workexperience';
import { Department } from './department';
import { Responsability } from './responsability';

export class Employee {
  id: number;
  email: string;
  department: Department;
  responsability: Responsability;
  workexperience: Workexperience;
  feedbackStatus: number;
  emailSent: boolean;
  constructor() {

  }
}
