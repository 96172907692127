import { UpdatePasswordDTO } from './../../../modules/password/dto/update-password-dto';
import { environment } from 'src/environments/environment';
import { PasswordRequestRepository } from './../../repositories/password-request-repository';
import { Injectable } from '@angular/core';
import { PasswordUpdateRepository } from '../../repositories/password-update-repository';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(private passwordRequestRepository: PasswordRequestRepository, private passwordUpdateRepository: PasswordUpdateRepository) { }

  public requestPasswordChange(recipientEmail: string){
    let url: string = environment.rootUrl + environment.endpoints.passwordRequest;
    return this.passwordRequestRepository.requestPasswordChange(url, recipientEmail);
  }

  public updatePassword(newPassword: UpdatePasswordDTO){
    let url: string = environment.rootUrl + environment.endpoints.passwordUpdate;
    return this.passwordUpdateRepository.updatePassword(url, newPassword);
  }
}
