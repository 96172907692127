import { SecureHeaderComponent } from './layout/header/secure-header/secure-header.component';
import { PublicLayoutComponent } from './layout/public-layout/public-layout.component';
import { SecureLayoutComponent } from './layout/secure-layout/secure-layout.component';
import { FooterComponent } from './layout/footer/footer.component';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';

import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { PublicHeaderComponent } from './layout/header/public-header/public-header.component';
import { CommonHeaderComponent } from './layout/header/common-header/common-header.component';
import { CommonFooterComponent } from './layout/footer/common-footer/common-footer.component';
import { LoginFooterComponent } from './layout/footer/login-footer/login-footer.component';
import { LoginHeaderComponent } from './layout/header/login-header/login-header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageSectionComponent } from './message-section/message-section/message-section.component';
import { SurveyFooterComponent } from './layout/footer/survey-footer/survey-footer.component';
import { SurveyLayoutComponent } from './layout/survey-layout/survey-layout.component';
import { LinkMethodosComponent } from './layout/header/link-methodos/link-methodos.component';

@NgModule({
  declarations: [
    FooterComponent,
    SecureLayoutComponent,
    PublicLayoutComponent,
    SurveyLayoutComponent,
    SecureHeaderComponent,
    ImpressumComponent,
    DatenschutzComponent,
    PublicHeaderComponent,
    CommonHeaderComponent,
    CommonFooterComponent,
    SurveyFooterComponent,
    LoginFooterComponent,
    LoginHeaderComponent,
    MessageSectionComponent,
    LinkMethodosComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  exports: [
    FooterComponent,
    ImpressumComponent,
    DatenschutzComponent,
    CommonHeaderComponent,
    CommonFooterComponent,
    LoginFooterComponent,
    LoginHeaderComponent,
    SurveyFooterComponent,
    MessageSectionComponent
  ]
})
export class CoreModule { }
