
import { Component, Input } from '@angular/core';

@Component({
  selector: 'methode-toogle',
  templateUrl: './methode-toogle.component.html',
  styleUrls: ['./methode-toogle.component.scss']
})
export class MethodeToogleComponent {

  @Input() title: string = "";
  @Input() showLine: boolean = true;
  @Input() titleUnderline: boolean = false;
  isOpen: boolean = false;

  constructor() {
  }

  changeToogleStatus() {
    this.isOpen = !this.isOpen;
  }

}
