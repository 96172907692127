export class FetchMethodeLinksDto {
  public videotutorial: string;
  public suggestedMethods: Array<SuggestedMethod> = new Array();
  public proposalApproaches: Array<ProposalApproache> = new Array();
  public networking: Array<Network> = new Array();
  public studentHelp: Array<StudentHelp> = new Array();
  public furtherEducation: Array<FurtherEducation> = new Array();

  constructor() { }

}
export class SuggestedMethod {
  public id: number;
  public methodName: string;
  constructor() { }
}
export class ProposalApproache {
  public description: string;
  public link: string;
  constructor() { }
}
export class Network {
  public description: string;
  public link: string;
  constructor() { }
}
export class StudentHelp {
  public description: string;
  public link: string;
  constructor() { }
}
export class FurtherEducation {
  public description: string;
  public link: string;
  constructor() { }
}
