import { SendEmailRepository } from './../../repositories/send-email/send-email-reposiroty';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  constructor(private sendEmailRepository:SendEmailRepository) { }

  sendEmailToEmployee(companyid:number, employeeId:number):Observable<any>{
    return this.sendEmailRepository.sendEmailFromCompanyToEmployee(companyid, employeeId);
  }
}
