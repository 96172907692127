import { IdealPointDto } from './../../../../modules/survey-circle/ideal-line-display/dto/ideal-point-dto';
import { AddIdealPointRepository } from './../../../repositories/companies/ideal-point/add-ideal-point-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IdealPointToIdealPointDtoMapper } from 'src/app/modules/survey-circle/ideal-line-display/mapper/ideal-point-to-ideal-point-dto-mapper';
import { IdealPoint } from 'src/app/modules/survey-circle/ideal-line-display/model/ideal-point';

@Injectable({
  providedIn: 'root'
})
export class AddIdealPointService {

  constructor(private idealPointRepository: AddIdealPointRepository) { }

  public addIdealPoint(companyId:number, idealPoint: IdealPoint):Observable<any>{
    return this.idealPointRepository.addIdealPointToCompany(companyId, IdealPointToIdealPointDtoMapper.map(idealPoint));
  }

}
