import { MethodosRepository } from '../../../repositories/methodos/methodos-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FetchSubtopicService {

  constructor(private methodosRepository: MethodosRepository) { }

  public getSubtopicsByMethodo(methodoId:number):Observable<any>{
    return this.methodosRepository.getSubtopicByMethodoId(methodoId);
  }
}
