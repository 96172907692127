import { MethodosRepository } from './../../../repositories/methodos/methodos-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FetchTopicService {

  constructor(private methodosRepository:MethodosRepository) { }

  public fetchMethodosByGroupId(groupId:number):Observable<any>{
    return this.methodosRepository.getMehotodoByGroupId(groupId);
  }
}
