import { GetFeedbackStatusRepository } from './../../../repositories/companies/get-feedbackstatus/get-feedbackstatus-repository';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetFeedbackstatusService {

  constructor(private getFeedbackStatusRepository: GetFeedbackStatusRepository) { }

  getFeedbackStatusFromCompany(companyId:number): Observable<any>{
    return this.getFeedbackStatusRepository.getFeedbackStatusFromCompany(companyId);

  }
}
